<div
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
  style="
    background-image: url('assets/images/dashboard/login.avif');
    height: 100vh;
  "
  id="mainDiv"
>
  <div
    class="auth-block center-div" [ngStyle]="{'width': showSelfOnboard ? 'fit-content' : '400px' }"
    style="
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 2%;
    "
    *ngIf="showLogin"
  >
  <div *ngIf="showSelfOnboard" (keydown)="closeAttach()" (click)="closeAttach();" style="float: right;"><em class="fa fa-window-close closeAttach" aria-hidden="true"></em></div>
    <div style="text-align: center; padding: 2% 0%">
      <img
        alt=""
        ng-src="assets/images/dashboard/unified-ai-logo-blue.avif"
        height="50"
        width="201"
        src="assets/images/dashboard/unified-ai-logo-blue.avif"
      />
    </div>
    <div *ngIf="showSelfOnboard" class="col-xs-12 col-sm-12 col-md-12" style="display: inline-flex;padding: 10px 40px;cursor: pointer;font-size: 16px;">
      <div (click)="selectedSignupChange('self')" (keydown)="selectedSignupChange('self')" style="padding: 1% 3% 1% 0%;">
        <input style="cursor: pointer; display: inline;" type="radio" value="self" id="self"
          class="radio_activity" [checked]="selectedSignupOption === 'self'" />
        &nbsp;<span class="radioOnboard">{{'login.self' | translate}}</span>
      </div>
      <div (click)="selectedSignupChange('reseller')" (keydown)="selectedSignupChange('reseller')" style="padding: 1%;">
        <input style="cursor: pointer; display: inline;" type="radio" value="reseller" id="reseller"
          class="radio_activity" [checked]="selectedSignupOption === 'reseller'" />
        &nbsp;<span class="radioOnboard">{{'login.reseller' | translate}}</span>
      </div>
    </div>
    <div *ngIf="showSelfOnboard && selectedSignupOption === 'self'">
      <app-self-service-onboard (dataEmitter)="handleReceivedData($event)"></app-self-service-onboard>
    </div>
    <div *ngIf="showSelfOnboard && selectedSignupOption === 'reseller'" style="width: 500px;">
      <form [formGroup]="UserForm" (ngSubmit)="onSignUp()">
        <div class="col-xs-12 col-sm-12 col-md-12" style="margin-top: 20px; margin-left: 26px;height: fit-content;max-height:65vh;overflow-y: scroll;padding: 0px;">
            <div id="loginlicensekey" class="col-xs-12 col-sm-12 col-md-12">
                <div class="col-xs-8 -group login-input" style="padding: 0px;"
                [ngStyle]="{ 'margin-bottom': showInfo ? '0px' : '55px' }">
                  <div class="inputContainer col-xs-12 col-sm-12 col-md-12" style="position: relative;top:-24px;padding: 0px;margin-bottom: 0px;">
                    <em
                      class="fas fa-key licenseKey"></em>
                    <input
                      type="text" id="loginLicensekey"
                      class="form-control Field"
                      name="validatekey"
                      formControlName="licensekey"
                      placeholder="{{ 'login.licensekey' | translate }}"
                      style="
                        border-radius: 5px;
                        color: #333333 !important;
                        width: 100%;
                        margin-top: -2.7rem;margin-bottom: 0px;
                      "
                      (keydown)="verifyKey()"
                      (keyup)="verifyKey()"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12" style="top: -20px;" *ngIf="clickValidate && ((UserForm.controls['licensekey'].invalid &&  UserForm.controls['licensekey'].errors.required) || (!showInfo &&
                  UserForm.controls['licensekey'].valid))">
                  <div *ngIf="clickValidate && UserForm.controls['licensekey'].invalid &&  UserForm.controls['licensekey'].errors.required"
                  class="text-danger">
                    {{ "login.key_required" | translate }}
                </div>
                <div
                  *ngIf="clickValidate && !showInfo"
                  class="text-danger"
                >
                  <div
                    *ngIf="
                      clickValidate &&
                      !showInfo &&
                      UserForm.controls['licensekey'].valid
                    "
                  >
                    {{ validationMsg }}
                  </div>
                </div>
              </div>
                </div>
                <div class="form-group col-xs-4 login-input submit" style="display: flex;">
                  <button id="licenseValidation"
                    class="form-control btn-auth"
                    style="
                      background-color: #386ba7;
                      border-radius: 5px;
                      color: white;"
                    [disabled]="showInfo"
                    type="button"
                    (click)="licenseValidation()"
                  >
                    {{ showInfo ? "VALIDATED" : "VALIDATE" }}
                  </button>
                  <em
                  class="fa fa-check-circle"
                  style="
                    font-size: 15px !important;
                    padding-top: 12px;
                    margin-left: 10px;
                    color: green;
                  "
                  aria-hidden="true"
                  *ngIf="showInfo"
                ></em>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 licenceForm">
          <div
            style="margin-left: 5px; margin-right: 30px"
            *ngIf="showInfo"
          >
            <div class="form-group" style="text-align: left;">
              <label for="logincompany" class="signup-label">{{
                "login.compnay" | translate
              }}</label>
              <div class="inputContainer" id="logincompany">
                <em
                  class="fas fa-briefcase"
                  style="
                    font-size: medium;
                    margin-top: 8px;
                    margin-left: 8px;
                  "
                ></em>
                <input
                  type="text" id="loginCompany"
                  class="form-control Field"
                  name="company"
                  formControlName="company"
                  placeholder="{{ 'login.compnay' | translate }}"
                  style="
                    border-radius: 5px;
                    color: #333333 !important;
                    width: 408px;
                    margin-top: -2.7rem;
                  "
                  autocomplete="off"
                  required
                  readonly
                  [attr.disabled]="true"
                />
              </div>
            </div>
          </div>

          <div style="margin-left: 5px" *ngIf="showInfo">
            <div class="form-group" style="text-align: left;">
              <label for="logingusername" class="signup-label">{{
                "login.user_name" | translate
              }}</label>
              <div class="inputContainer" id="logingusername">
                <em
                  class="fas fa-user"
                  style="
                    font-size: medium;
                    margin-top: 8px;
                    margin-left: 8px;
                  "
                ></em>
                <input
                  type="text" id="loginusername2"
                  class="form-control Field"
                  name="validatekey"
                  formControlName="username"
                  placeholder="{{ 'login.user_name' | translate }}"
                  style="
                    border-radius: 5px;
                    color: #333333 !important;
                    width: 408px;
                    margin-top: -2.7rem;
                  "
                  autocomplete="off"
                  required
                  readonly
                  [attr.disabled]="true"
                />
              </div>
            </div>
          </div>

          <div style="margin-left: 5px" *ngIf="showInfo">
            <div class="form-group" style="text-align: left;">
              <label for="loginpassword" class="signup-label">{{
                "login.password" | translate
              }}</label>
              <div class="inputContainer" id="loginpassword">
                <em
                  class="fas fa-lock"
                  style="
                    font-size: medium;
                    margin-top: 8px;
                    margin-left: 8px;
                  "
                ></em>
                <input id="loginPassword2"
                  type="password"
                  class="form-control Field"
                  name="password"
                  formControlName="password"
                  placeholder="{{ 'login.password' | translate }}"
                  style="
                    border-radius: 5px;
                    color: #333333 !important;
                    background-color: white;
                    width: 408px;
                    margin-top: -2.7rem;
                  "
                  autocomplete="off"
                  required
                />
              </div>
              <div
                *ngIf="submitted && UserForm.controls['password'].invalid"
                class="text-danger"
              >
                <div *ngIf="UserForm.controls['password'].errors.required">
                  {{ "login.password_required" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div style="margin-left: 5px" *ngIf="showInfo">
            <div class="form-group" style="text-align: left;">
              <label for="loginconfirmpassword" class="signup-label">{{
                "login.confirm_password" | translate
              }}</label>
              <div class="inputContainer" id="loginconfirmpassword">
                <em
                  class="fas fa-lock"
                  style="
                    font-size: medium;
                    margin-top: 8px;
                    margin-left: 8px;
                  "
                ></em>
                <input id="loginConfirmPassword"
                  type="password"
                  class="form-control Field"
                  name="password"
                  formControlName="confirm"
                  placeholder="{{ 'login.confirm_password' | translate }}"
                  style="
                    border-radius: 5px;
                    color: #333333 !important;
                    background-color: white;
                    width: 408px;
                    margin-top: -2.7rem;
                  "
                  autocomplete="off"
                  required
                />
              </div>
              <div
                *ngIf="
                  passwordValidate &&
                  UserForm.get('confirm').errors.confirmPasswordValidator
                "
                class="text-danger"
              >
                <div
                  *ngIf="
                    passwordValidate &&
                    UserForm.get('confirm').errors.confirmPasswordValidator
                  "
                >
                  {{ "login.password_must_match" | translate }}
                </div>
              </div>
            </div>
          </div>
        
          <div class="form-group" *ngIf="showInfo">
            <button id="loginSignUp"
              class="form-control"
              style="
                background-color: #386ba7;
                border-radius: 5px;
                color: white;
                width: 409px;
                margin-left: 4px;
                margin-top: 35px;
              "
              type="submit"
            >
              {{ "login.sign_up" | translate }}
            </button>
          </div>
        </div>
        </div>
      </form>
    </div>
    <div class="auth-block-body" *ngIf="!showSelfOnboard">
      <form
        name="loginForm"
        (ngSubmit)="loginForm.form.valid && sign_in(user)"
        #loginForm="ngForm"
        novalidate
      >
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
          style="padding: 5% 5%; display: grid"
        >
          <div class="form-group">

            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
              [class.error-textbox]="error.status === true"
            >
              <label for="loginUserName"
                style="
                  color: #333333;
                  text-align: start;
                  width: inherit;
                  margin-bottom: 5px;
                  font-weight: 500;
                "
                >{{ "login.username" | translate }}</label>
              <div>
                <div class="input-group-prepend" id="loginusername">
                  <span class=""
                    ><em
                      class="fas fa-user"
                      style="color: black; font-size: medium"
                    ></em
                  ></span>
                </div>
                <input id="loginUserName"
                  type="text"
                  class="form-control"
                  name="user"
                  [(ngModel)]="user.username"
                  style="
                    padding-left: 10%;
                    border-radius: 5px;
                    color: #333333 !important;
                  "
                  i18n-placeholder="@@placeHolderUserName"
                  placeholder="{{ 'login.username' | translate }}"
                  #userName="ngModel"
                  autocomplete="username"
                  required
                />
              </div>
              <span class="loginError" i18n="@@usernameRequired" *ngIf="(loginForm.submitted && userName.invalid) || (userName.errors && (userName.dirty || userName.touched))">
                {{ "login.username_required" | translate }}
              </span>
            </div>
          </div>

          <div class="form-group" style="margin-bottom: 0px">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
              [class.error-textbox]="error.status === true"
            >
              <label for="loginpassword"
                style="
                  color: #333333;
                  text-align: start;
                  width: inherit;
                  margin-bottom: 5px;
                  font-weight: 500;
                "
                >{{ "login.password" | translate }}</label>
              <div>
                <div class="input-group-prepend" id="loginpassword">
                  <span class=""
                    ><em
                      class="fas fa-lock"
                      style="color: black; font-size: medium"
                    ></em
                  ></span>
                </div>
                <input id="loginPassword"
                  type="password"
                  class="form-control"
                  name="password"
                  [(ngModel)]="user.password"
                  style="
                    padding-left: 10%;
                    border-radius: 5px;
                    color: #333333 !important;
                  "
                  i18n-placeholder="@@placeHolderPassword"
                  placeholder="{{ 'login.password' | translate }}"
                  #password="ngModel"
                  autocomplete="current-password"
                  required
                />
              </div>
              <span class="loginError" i18n="@@passwordRequired" *ngIf="(loginForm.submitted && password.invalid) || (password.errors && (password.dirty || password.touched))">
                {{ "login.password_required" | translate }}
              </span>
            </div>
          </div>
          <div class="form-group" style="margin-top: 12px">
            <div style="width: 151px; float: right; margin-top: 2px" id="loginForgotPassword" id="loginsignup">
              <label for="loginforgotpassword"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
                (click)="showTemplate(forgotPassword)"
                (keydown)="showTemplate(forgotPassword)"
                style="
                  cursor: pointer;
                  font-weight: 500;
                  margin-bottom: 0px;
                  margin-top: -2%;
                  color: #333333;
                "
                name="forgetpassword"
                id="forgetpassword"
                >{{ "login.forgot_password" | translate }}</label>
            </div>
          </div>
          <div class="form-group" style="margin-bottom: 0px" id="loginforgotpassword">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
              <button id="loginSubmit"
                class="btn-default btn-auth"
                type="submit"
                style="
                  background-color: #386ba7;
                  border-radius: 5px;
                  box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, 0.2),
                    0 0 14px 0 rgba(30, 135, 240, 0.3);
                "
              >
                {{ "login.title" | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="ssoEnabled" class="form-group">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="text-align: left;">
            <label for="login.sign_in_using" style="cursor: pointer;font-weight: 500;margin-bottom: 0px; margin-top: 5%; color: #333333;
                text-align: left;" name="forgetpassword">{{ "login.sign_in_using" | translate }}
            </label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input" style="padding-top: 16px;" id="login.sign_in_using">
            <!-- Login with Google -->
            <div style="float: left;cursor: pointer;" id="googleSignin" class="googlSignin" (click)="loginUsingGoogle()" (keydown)="loginUsingGoogle()">
              <em class="fa-brands fa-google" style="font-size: 22px;"></em>
            </div>
            <span class="loginError" i18n="@@error" *ngIf="googleError" style="padding: 5px;">
              {{ googleError }}
            </span>
        <!-- Login with Linkedin -->
        <div style="float: left; padding-left: 20px;">
          <div class="app-linkedin-login btn btn-linkedin" (click)="loginUsingLinkedIn()" (keydown)="loginUsingLinkedIn()">
            <em class="fa fa-linkedin" style="font-size: 22px;"></em></div>
        </div>
      </div>
       </div>
       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group" style="margin-top: 10px;">
        <div for="loginsignup"
          style="
            font-weight: 500;
            color: #333333;
          "
          name="signup"
          id="signup"
          >Don't have an account? &nbsp;<a (click)="showTemplateSignUp()"
          (keydown)="showTemplateSignUp()" style="color: #0034AC !important;
            cursor: pointer;">{{ "login.signUp" | translate }}</a></div>
      </div>
        </div>
      </form>
    </div>

    <ng-template #forgotPassword>
      <div class="">
        <div
          class="col-md-12"
          class="modal-content"
          style="border-radius: 6px 6px 0px 0px !important"
        >
          <div class="modal-header headerModal" style="text-align: center">
            <button type="button" class="close" (click)="onCancel()">
              &times;
            </button>
            <h4 class="modal-title">
              {{ "login.forgot_password" | translate }}
            </h4>
          </div>

          <div style="padding: 2%">
            <div style="text-align: center; padding: 1% 2% 3% 2%">
              <span style="font-size: 18px">{{
                "login.reset_message" | translate
              }}</span>
            </div>
            <div class="auth-block-body" style="height: 85px; width: auto">
              <div class="col-xs-9 login-input" style="padding: 2%">
                <div class="form-group">
                  <div class="col-xs-12 login-input">
                    <input id="forgotPasswordEmail"
                      type="text"
                      class="form-control"
                      name="email"
                      [(ngModel)]="email"
                      placeholder="{{ 'common.email' | translate }}"
                      style="border-radius: 5px; color: #333333 !important"
                      autocomplete="off"
                      required
                    />
                    <span id="email_requires"
                      *ngIf="
                        forgotPasswordSubmitted &&
                        (email == '' || email == undefined || email == null)
                      "
                    >
                      {{ "login.email_required" | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-3 login-input submit" style="padding: 2%">
                <div
                  class="login-submit"
                  style="
                    padding: 0;
                    margin: 0;
                    height: auto;
                    background-color: #386ba7;
                    border-radius: 5px;
                  "
                >
                  <button id="submitForgotPassword"
                    class="btn-default btn-auth"
                    type="button"
                    (click)="submitForgotPassword()"
                  >
                    {{ "common.submit" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

