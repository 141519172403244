import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  applyChannelIconColor(channelName: any): string {
    let returnElement = '';
    if (channelName !== undefined && channelName !== '' && channelName !== null ) {
    switch (channelName.toLowerCase()) {
      case 'sms':
        returnElement = 'f4ac0c';
        break;
      case 'viberpa':
        returnElement = '#2d275b';
        break;
      case 'fbmessenger':
        returnElement = ' #1E87F0';
        break;
      case 'wechat':
        returnElement = '#2DC100';
        break;
      case 'whatsappofficial':
        case 'whatsappcloud':
       case 'WhatsApp Cloud':
        case 'WhatsAppCloud':
        case 'Whatsappcloud':
        case 'Whatsapptrial':
        case 'WhatsAppTrial':
        returnElement = '#2AC940';
        break;
      case 'line':
      case 'whatsapp':
        case 'whatsapp trial':
        returnElement = '#2AC940';
        break;
      case 'bbm':
        returnElement = '#3D3D3D';
        break;
      case 'kik':
        returnElement = '#5DCD11';
        break;
      case 'skype':
      case 'Skype':
      case 'telegram':
        returnElement = '#2da5d9';
        break;
      case 'zalo':
        returnElement = '#0068ff';
        break;
      case 'threema':
        returnElement = '#05CCFF';
        break;
      case 'alexa':
        returnElement = '#2AC940';
        break;
      case 'chatbot':
        returnElement = '#1E87F0';
        break;
      case 'webchat':
        returnElement = '#1E87F0';
        break;
      case 'android':
        returnElement = '#A4C639';
        break;
      case 'ios':
        returnElement = '#A2AAAD';
        break;
      case 'tclchatwidget':
        returnElement = '#1E87F0';
          break;
      case 'applebusinesschat':
        returnElement = '#000000';
        break;
      case 'googlechat':
        returnElement = '#2aba25';
        break;
      case 'zoom':
        returnElement = '#2d8cff';
        break;
      case 'vkconnector':
      case 'vkmessenger':
        returnElement = '#1c89e4';
        break;
      case 'Airbnb':
      case 'airbnb':
        returnElement = '#ff5157';
        break;
      case 'Twitter' :
      case 'twitter' :
        returnElement = '#1D9BF0';
        break;
      default:
        returnElement = '#2AC940';
        break;
    }
  }
    return returnElement;
  }

  applyChannelIcon(channelName): string[] {
    let returnElement;
    if (channelName !== undefined && channelName !== '' && channelName != null ) {
    switch (channelName.toLowerCase()) {
      case 'sms':
        returnElement = ['fab', 'fa-comments'];
        break;
      case 'viberpa':
        returnElement = ['fab', 'fa-viber'];
        break;
      case 'fbmessenger':
        returnElement = ['fab', 'fa-facebook-messenger'];
        break;
      case 'wechat':
        returnElement = ['fab', 'fa-weixin'];
        break;
      case 'whatsappofficial':
      case 'whatsapp cloud':
      case 'whatsappcloud':
      case 'whatsapptrial':
        returnElement = ['fab', 'fa-whatsapp'];
        break;
      case 'whatsapp':
        case 'whatsapp trial':
        returnElement = ['fab', 'fa-whatsapp'];
        break;
      case 'bbm':
        returnElement = ['fab', 'fa-blackberry'];
        break;
      case 'telegram':
        returnElement = ['fab', 'fa-telegram'];
        break;
      case 'discord':
        returnElement = ['fab', 'fa-discord'];
        break;
      case 'signal':
      case 'kik':
      case 'threema':
      case 'alexa':
        returnElement = ['fab', 'custom-icon', 'custom-' + channelName];
        break;
      case 'chatbot':
        returnElement = ['fa-solid', 'fa-globe'];
        break;
      case 'webchat':
        returnElement = ['fa-solid', 'fa-globe'];
        break;
      case 'android':
        returnElement = ['fab', 'fa-android'];
        break;
      case 'ios':
        returnElement = ['fab', 'fa-apple'];
        break;
      case 'tclchatwidget':
          returnElement = ['fa-solid', 'fa-globe'];
          break;
      case 'applebusinesschat':
        returnElement = ['fab', 'fa-apple'];
        break;
      case 'googlechat':
      case 'googlebusiness':
        returnElement = ['fab', 'fa-google'];
        break;
      case 'zoom':
        returnElement = ['fas', 'fa-video'];
        break;
      case 'vkconnector':
      case 'vkmessenger':
        returnElement = ['fab', 'fa-vk'];
        break;
      case 'Airbnb':
      case 'airbnb':
        returnElement = ['fab', 'fa-airbnb'];
        break;
      case 'Line':
      case 'line':
        returnElement = ['fab', 'fa-line'];
        break;
      case 'Skype':
      case 'skype':
        returnElement = ['fab', 'fa-skype'];
        break;
      case 'Instagram':
      case 'instagram' :
        returnElement = ['fab', 'fa-instagram'];
        break;
      case 'twitter' :
        returnElement = ['fab', 'fa-twitter'];
        break;
      default:
        returnElement = ['fab', 'fa-' + channelName];
        break;
    }
  }
    return returnElement;
  }

  applyMediaIcon(mediaType): string[] {
    mediaType = mediaType.toLowerCase();
    let returnElement = ['far'];
    if (mediaType) {
      switch (mediaType.toLowerCase()) {
        case '':
          returnElement = ['far', 'fa-comment'];
          break;
        case 'text':
          returnElement = ['far', 'fa-comment'];
          break;
        case 'voice':
        case 'audio':
          returnElement = ['fas', 'fa-microphone'];
          break;
        case 'video':
          returnElement = ['fa fa-video-camera'];
          break;
        case 'file':
        case 'document':
          returnElement = [' fa fa-file'];
          break;
        case 'image':
          returnElement = ['far', 'fa-image'];
          break;
        case 'map location':
        case 'map':
        case 'location':
          returnElement = ['fas', 'fa-location-arrow'];
          break;
        default:
          returnElement = ['far', 'fa-comment'];
      }
    }
    return returnElement;
  }

  applyChannelName(channelValue: any): string {
    let returnElement = '';
    if (channelValue !== undefined && channelValue !== '' && channelValue != null ) {
    switch (channelValue.toLowerCase()) {
      case 'sms':
        returnElement = 'SMS';
        break;
      case 'viberpa':
        returnElement = 'Viber';
        break;
      case 'viberpa1':
        returnElement = 'Viber Public Account';
      break;
      case 'fbmessenger':
        returnElement = 'FB Messenger';
        break;
      case 'wechat':
        returnElement = 'WeChat';
        break;
      case 'whatsappofficial':
      case 'whatsapp':
      case 'whatsapp cloud':
      case 'whatsappcloud':
        returnElement = 'WhatsApp';
        break;
      case 'whatsapptrial':
      case 'whatsapp trial':
      case 'WhatsApp Trial':
      case 'WhatsAppTrial':
        returnElement = 'WhatsApp Trial';
        break;
      case 'bbm':
        returnElement = 'BBM';
        break;
      case 'kik':
        returnElement = 'Kik';
        break;
      case 'telegram':
        returnElement = 'Telegram';
        break;
      case 'zalo':
        returnElement = 'Zalo';
        break;
      case 'threema':
        returnElement = 'Threema';
        break;
      case 'alexa':
        returnElement = 'Alexa';
        break;
      case 'chatbot':
        returnElement = 'Chatbot(Web Widget)';
        break;
      case 'webchat':
        returnElement = 'Webchat';
        break;
      case 'ios':
        returnElement = 'iOS';
        break;
      case 'android':
        returnElement = 'Android';
        break;
      case 'applebusinesschat':
        returnElement = 'Apple Business Chat';
        break;
      case 'applebusinesschat1' :
        returnElement = 'Apple Messages for Business';
        break;
      case 'googlechat':
        returnElement = 'Google Chat';
        break;
      case 'zoom':
        returnElement = 'Zoom';
        break;
      case 'vkmessenger':
        returnElement = 'VK Messenger';
        break;
      case 'airbnb':
        returnElement = 'Airbnb';
        break;
      case 'discord':
        returnElement = 'Discord';
        break;
      case 'line':
        returnElement = 'Line';
        break;
      case 'microsoftteams':
      case 'msteams':
        returnElement = 'Microsoft Teams';
        break;
      case 'signal':
        returnElement = 'Signal';
        break;
      case 'skype':
        returnElement = 'Skype';
        break;
      case 'slack':
        returnElement = 'Slack';
        break;
      case 'xero':
        returnElement = 'Xero';
        break;
      default:
        returnElement = channelValue;
        break;
    }
    switch (channelValue.toLowerCase()) {
      case 'googleplay':
        returnElement = 'Google Play';
        break;
      case 'drift':
        returnElement = 'Drift';
        break;
      case 'facebook':
        returnElement = 'Facebook';
        break;
      case 'googlebusiness':
        returnElement = 'Google Business';
        break;
      case 'googlebusiness1':
        returnElement = 'Google Business Messages';
        break;
      case 'audiocodes':
        returnElement = 'Audio Codes';
        break;
      case 'webex':
        returnElement = 'Webex';
        break;
      case 'icq':
        returnElement = 'ICQ';
        break;
      case 'instagram':
        returnElement = 'Instagram';
        break;
      case 'lark':
        returnElement = 'Lark';
        break;
      case 'twilio':
        returnElement = 'Twilio';
        break;
      case 'telerivet':
        returnElement = 'Telerivet';
        break;
      case 'twist':
        returnElement = 'Twist';
        break;
      case 'tclchatwidget':
        returnElement = 'Chat Widget';
        break;
      case 'twitter':
        returnElement = 'Twitter';
        break;
      default:
        break;
    }
  }
    return returnElement;
  }

  applyChannelNameForDropDown(channelValue: any) {
    let returnElement = {};
    if (channelValue !== undefined && channelValue != null ) {
        switch (channelValue.toLowerCase()) {
          case 'sms':
            returnElement = {
              name: 'SMS',
              value: 'sms',
            };
            break;
          case 'viberpa':
            returnElement = {
              name: 'Viber',
              value: 'viberpa',
            };
            break;
          case 'fbmessenger':
            returnElement = {
              name: 'FB Messenger',
              value: 'fbmessenger',
            };
            break;
          case 'wechat':
            returnElement = {
              name: 'WeChat',
              value: 'wechat',
            };
            break;
          case 'whatsappofficial':
          case 'whatsapp':
            returnElement = {
              name: 'WhatsApp',
              value: 'whatsappofficial',
            };
            break;
          case 'whatsappcloud':
          case 'whatsapp cloud' :
            returnElement = {
              name: 'WhatsApp',
              value: 'whatsappcloud',
            };
            break;
          case 'bbm':
            returnElement = {
              name: 'BBM',
              value: 'bbm',
            };
            break;
          case 'kik':
            returnElement = {
              name: 'Kik',
              value: 'kik',
            };
            break;
          case 'telegram':
            returnElement = {
              name: 'Telegram',
              value: 'telegram',
            };
            break;
          case 'zalo':
            returnElement = {
              name: 'Zalo',
              value: 'zalo',
            };
            break;
          case 'threema':
            returnElement = {
              name: 'Threema',
              value: 'threema',
            };
            break;
          case 'alexa':
            returnElement = {
              name: 'Alexa',
              value: 'alexa',
            };
            break;
          case 'chatbot':
            returnElement = {
              name: 'ChatBot',
              value: 'chatbot',
            };
            break;
          case 'webchat':
            returnElement = {
              name: 'Webchat',
              value: 'webchat',
            };
            break;
          case 'android':
            returnElement =  {
              name: 'Android',
              value: 'android',
            };
            break;
          case 'ios':
            returnElement =  {
              name: 'iOS',
              value: 'ios',
            };
            break;
          case 'tclchatwidget':
            returnElement = {
              name: 'Chat Widget',
              value: 'tclchatwidget',
            };
            break;
          case 'applebusinesschat':
            returnElement = {
              name: 'Apple Business',
              value: 'applebusinesschat',
            };
            break;
          case 'googlechat':
            returnElement = {
              name: 'Google Chat',
              value: 'googlechat',
            };
            break;
          case 'zoom':
            returnElement = {
              name: 'Zoom',
              value: 'zoom',
            };
            break;
          case 'vkmessenger':
            returnElement = {
              name: 'VK Messenger',
              value: 'vkmessenger',
            };
            break;
          case 'airbnb':
            returnElement = {
              name: 'Airbnb',
              value: 'airbnb',
            };
            break;
          case 'discord':
            returnElement = {
              name: 'Discord',
              value: 'discord',
            };
            break;
          case 'microsoftteams':
          case 'microsoft teams' :
            returnElement = {
              name: 'Microsoft Teams',
              value: 'microsoftteams',
            };
            break;
          case 'msteams':
            returnElement = {
              name: 'Microsoft Teams',
              value: 'msteams',
            };
            break;
          case 'signal':
            returnElement = {
              name: 'Signal',
              value: 'signal',
            };
            break;
          case '':
              returnElement = {
                name: 'Others',
                value: 'others',
              };
              break;
          default:
            returnElement = {
              name: channelValue,
              value: channelValue,
            };
            break;
        }
        switch (channelValue.toLowerCase()) {
          case 'line':
            returnElement = {
              name: 'Line',
              value: 'line',
            };
            break;
          case 'skype':
            returnElement = {
              name: 'Skype',
              value: 'skype',
            };
            break;
          case 'slack':
            returnElement = {
              name: 'Slack',
              value: 'slack',
            };
            break;
          case 'xero':
            returnElement = {
              name: 'Xero',
              value: 'xero',
            };
            break;
          case 'googleplay':
            returnElement = {
              name: 'Google Play',
              value: 'googleplay',
            };
            break;
          case 'drift':
            returnElement = {
              name: 'Drift',
              value: 'drift',
            };
            break;
          case 'facebook':
            returnElement = {
              name: 'Facebook',
              value: 'facebook',
            };
            break;
          case 'googlebusiness':
            returnElement = {
              name: 'Google Business Message',
              value: 'googlebusiness',
            };
            break;
          case 'telerivet':
            returnElement = {
              name: 'Telerivet',
              value: 'telerivet',
            };
            break;
          case 'twist':
            returnElement = {
              name: 'Twist',
              value: 'twist',
            };
            break;
          case 'email':
            returnElement = {
              name: 'Email',
              value: 'email'
            };
            break;
          case 'whatsapptrial':
            returnElement = {
              name: 'WhatsApp Trial',
              value: 'whatsapptrial'
            };
            break;
          case 'instagram':
            returnElement = {
              name: 'Instagram',
              value: 'instagram'
            };
            break;
          case 'mattermost':
            returnElement = {
              name: 'Mattermost',
              value: 'mattermost'
            };
            break;
            case 'lark':
              returnElement = {
                name: 'Lark',
                value: 'lark'
              };
              break;
            case 'icq':
              returnElement = {
                name: 'ICQ',
                value: 'icq'
              };
              break;
            case 'kakaotalk':
              returnElement = {
                name: 'KakaoTalk',
                value: 'kakaotalk'
              };
              break;
          default:
            break;
        }
      }
        return returnElement;
  }

  getMenuIcon(name) {
    let returnValue;
    if (name != null && name !== undefined && name !== '') {
      switch (name.toLowerCase()) {
        case 'dashboard':
          returnValue = 'assets/images/menuicons/dashboard.avif';
          break;
        case 'history':
        case 'conversations':
        case 'live feed':
        case 'live agents':
        case 'average messages':
        case 'longest handling time':
        case 'active sessions':
        case 'sessions':
        case 'active conversations':
          returnValue = 'assets/images/menuicons/conversations.avif';
          break;
        case 'message templates':
        case 'templates':
        case 'list templates':
        case 'content':
        case 'content elements':
        case 'conversation elements':
        case 'conversational element':
        case 'flow builder':
        case 'variable':
        case 'api explorer':
        case 'rag':
          case 'flex':
          returnValue = 'assets/images/menuicons/content.avif';
          break;
        case 'tags':
          returnValue = 'assets/images/menuicons/tags.svg';
          break;
        case 'analytics':
        case 'reports':
          returnValue = 'assets/images/menuicons/reports.avif';
          break;
        case 'broadcast list':
        case 'active channels':
        case 'broadcast':
          returnValue = 'assets/images/menuicons/broadcast.avif';
          break;
        case 'message list':
        case 'messaging list':
        case 'set messaging list':
          returnValue = 'assets/images/menuicons/message.avif';
          break;
        case 'create template':
          returnValue = 'assets/images/menuicons/templates.avif';
          break;
        case 'edit template':
          returnValue = 'assets/images/menuicons/templates.avif';
          break;
        case 'contact lists':
        case 'create contact list':
        case 'contact':
          returnValue = 'assets/images/menuicons/contact.avif';
          break;
        case 'profile settings':
        case 'users':
        case 'user management':
          returnValue = 'assets/images/menuicons/multiple-users.avif';
          break;
        case 'active users':
        case 'audience':
        case 'contacts':
        case 'set contact list':
          returnValue = 'assets/images/menuicons/audience.avif';
          break;
        case 'categories':
          returnValue = 'assets/images/menuicons/categories.avif';
          break;
        case 'project':
        case 'projects':
        case 'smartcontact™':
        case 'smartcontact':
          case 'digital assistant':
            case 'ai agent':
          returnValue = 'assets/images/menuicons/smart.avif';
          break;
        case 'agent routing':
        case 'allowed users':
            returnValue = 'assets/images/menuicons/routing.avif';
          break;
        case 'average handling time':
        case 'average first response time':
          returnValue = 'assets/images/menuicons/tile1.avif';
          break;
        case 'settings':
          returnValue = 'assets/images/menuicons/settings.avif';
          break;
        case 'trial users':
        case 'trial user':
        case 'agent groups':
          returnValue = 'assets/images/menuicons/trialUsersb.avif';
          break;
        case 'audit trails':
          returnValue = 'assets/images/menuicons/auditTrial.avif';
          break;
        case 'quickreply':
          returnValue = 'assets/images/menuicons/Vector.svg';
          break;
        case 'flow-responses': 
          returnValue = 'assets/images/menuicons/content.avif';
          break;
        case 'whatsapp_flow':
          returnValue = 'assets/images/dashboard/Whatsapp.png';
          break;
        default:
          returnValue = '';
        }
      return returnValue;
    } else {
        return returnValue;
    }
  }
}
