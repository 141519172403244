import { LOCALE_ID, NgModule , NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeviceDetectorService} from 'ngx-device-detector';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule , HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { DropdownModule } from 'primeng/dropdown';
import { TagInputModule } from 'ngx-chips';
import { Interceptor } from './interceptor';
import { Interceptortoken } from './interceptortoken';
import { DashboardComponent } from './dashboard.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './home/navbar/navbar.component';
import { MsgCenterComponent } from './home/navbar/msg-center/msg-center.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { DialogOverviewExampleDialog } from './home/analytics/analytics.component';
import { PageHeaderComponent } from './home/shared/page-header/page-header.component';
import { OverviewCardComponent } from './home/overview/overview-card/overview-card.component';
import { PanelComponent } from './home/shared/panel/panel.component';
import { MessageContainerComponent } from './home/overview/message-container/message-container.component';
import { DateRangePickerComponent } from './home/shared/date-range-picker/date-range-picker.component';
import { PieChartComponent } from './home/shared/pie-chart/pie-chart.component';
import { BarChartComponent } from './home/shared/bar-chart/bar-chart.component';
import { PaginationComponent } from './home/shared/pagination/pagination.component';
import { ListComponent } from './home/conversation/list/list.component';
import { MenubarComponent } from './home/shared/menubar/menubar.component';
import { DateToGoPipe } from '../date-to-go.pipe';
import { TruncatePipe } from '../truncate.pipe';
import { ArrayReversePipe } from '../array-reverse.pipe';
import { CardComponent } from './home/shared/card/card.component';
import { PromptModalComponent } from './home/shared/prompt-modal/prompt-modal.component';
import { ErrorComponent } from './error/error.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import { MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatSortModule } from '@angular/material/sort';
import {MatSelectModule} from '@angular/material/select';
import { MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BranchComponent } from './home/branch/branch.component';
import { FlowBuilderdummyComponent } from './home/flow-builder/flow-builder.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxSmartModalModule , NgxSmartModalService} from 'ngx-smart-modal';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { DiagramAllModule, SymbolPaletteAllModule, OverviewAllModule } from '@syncfusion/ej2-angular-diagrams';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { DropDownListAllModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBoxModule, ColorPickerModule, UploaderModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { ButtonModule, CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { HoldableDirective } from './home/conversation/engage/holdable.directive';
import { AppselectorComponent } from './appselector/appselector.component';
import { AddnewagentComponent } from './home/agentallocation/addnewagent/addnewagent.component';
import { ListTemplatesComponent } from './home/templates/list-templates/list-templates.component';
import { RegisterComponent } from './register/register.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DateFilterPipe } from './date-filter.pipe';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { SetpasswordComponent } from './home/setpassword/setpassword.component';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { UsersComponent } from './home/users/users.component';
import { QuillModule } from 'ngx-quill';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {UniversalDeviceDetectorService} from '../universal-device-detector.service';
import { TooltipModule } from 'ng2-tooltip-directive-ng13fix';
import { FlowbuilderV3Component } from './home/flowbuilder-v3/flowbuilder-v3.component';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { DigitalAssistantComponent } from './digital-assistant/digital-assistant.component';
import { FlowbuilderPreviewComponent } from './home/flowbuilder-v3/flowbuilder-preview/flowbuilder-preview.component';
import { RagComponent } from './home/rag/rag.component';
import { QuickreplyComponent } from './home/quickreply/quickreply.component';
import { WhatsappFlowListComponent } from './home/whatsapp-flows/whatsapp-flows.component';
import { FlexMessagesComponent } from './home/flex-messages/flex-messages.component';
import { ListFlexMessagesComponent } from './home/flex-messages/list-flex-messages/list-flex-messages.component';
import { FlexBroadcastComponent } from './home/flex-broadcast/flex-broadcast.component';
import { ImageLayoutComponent } from './home/image-layout/image-layout.component';
import { FlowResponsesComponent } from './home/flow-responses/flow-responses.component';
import { RichMenusComponent } from './home/rich-menus/rich-menus.component';
import { RichmenuListComponent } from './home/rich-menus/richmenu-list/richmenu-list.component';
import { SelfServiceOnboardComponent } from './self-service-onboard/self-service-onboard.component';
import { SignupComponent } from './signup/signup.component';
import { SelfIntroComponent } from './home/self-intro/self-intro.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const COMPONENT = [
    AppselectorComponent,
        HomeComponent,
        SidebarComponent,
        PageHeaderComponent,
        OverviewCardComponent,
        PanelComponent,
        MessageContainerComponent,
        TruncatePipe,
        DateToGoPipe,
        ArrayReversePipe,
        DateRangePickerComponent,
        PieChartComponent,
        BarChartComponent,
        PaginationComponent,
        MenubarComponent,
        CardComponent,
        ErrorComponent,
        HoldableDirective,
];
@NgModule({
    declarations: [

        AppselectorComponent,
        DashboardComponent,
        LoginComponent,
        HomeComponent,
        NavbarComponent,
        MsgCenterComponent,
        SidebarComponent,
        PageHeaderComponent,
        OverviewCardComponent,
        PanelComponent,
        MessageContainerComponent,
        TruncatePipe,
        DateToGoPipe,
        ArrayReversePipe,
        DateRangePickerComponent,
        PieChartComponent,
        BarChartComponent,
        PaginationComponent,
        ListComponent,
        MenubarComponent,
        CardComponent,
        PromptModalComponent,
        ErrorComponent,
        DialogOverviewExampleDialog,
        BranchComponent,
        FlowBuilderdummyComponent,
        AddnewagentComponent,
        HoldableDirective,
        ListTemplatesComponent,
        RegisterComponent,
        DateFilterPipe,
        SetpasswordComponent,
        UsersComponent,
        FlowbuilderV3Component,
        DigitalAssistantComponent,
        FlowbuilderPreviewComponent,
        RagComponent,
        QuickreplyComponent,
        WhatsappFlowListComponent,
        FlexMessagesComponent,
        ListFlexMessagesComponent,
        FlexBroadcastComponent,
        ImageLayoutComponent,
        FlowResponsesComponent,
        RichMenusComponent,
        RichmenuListComponent,
        SelfServiceOnboardComponent,
        SignupComponent,
        SelfIntroComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxSpinnerModule,
        NgbModule,
        MatTabsModule,
        MatTableModule,
        MatRadioModule,
        MatSortModule,
        MatSelectModule,
        MatTooltipModule,
        MatButtonModule,
        AppRoutingModule,
        InfiniteScrollModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        TranslateModule,
        UiSwitchModule,
        AmChartsModule,
        SelectDropDownModule,
        MatCardModule,
        MatCheckboxModule,
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot(),
        TimepickerModule.forRoot(),
        DropdownModule,
        TagInputModule,
        AngularDraggableModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        PickerModule,
        DiagramAllModule,
        SymbolPaletteAllModule,
        OverviewAllModule,
        ToolbarModule,
        DiagramAllModule,
        SymbolPaletteAllModule,
        OverviewAllModule,
        ButtonModule,
        ColorPickerModule,
        CheckBoxModule,
        ToolbarModule,
        DropDownButtonModule,
        UploaderModule,
        DropDownListAllModule,
        ListViewAllModule,
        DialogAllModule,
        TextBoxModule,
        RadioButtonModule,
        MultiSelectModule,
        NumericTextBoxModule,
        NgSelectModule,
        NgxSmartModalModule.forRoot(),
        PopoverModule.forRoot(),
        MatDialogModule,
        NgxGraphModule,
        ContextMenuModule,
        QuillModule.forRoot(),
        TooltipModule,
        DragAndDropModule,
    ],
    exports: [HoldableDirective, COMPONENT, NgxGraphModule, PageHeaderComponent, SelfServiceOnboardComponent],
    providers: [
        {
            provide: DeviceDetectorService,
            useClass: UniversalDeviceDetectorService,

        },
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: Interceptortoken, multi: true },
        NgxSmartModalService,
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class DashboardModule {}
