<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style="padding:0px;">
  <div class="page-loader" *ngIf="loadershow">
    <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
  </div>
  <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="padding-left:0px;">
    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="padding-left:0px;">
      <app-page-header i18n-pageTitle="@@contacts_title" pageTitle="{{'whatsapp_flow.title' | translate}}"
        EngTitle="whatsapp_flow"></app-page-header>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;text-align: right;margin-bottom: 3%">
      <form class="search">
        <div class="search__wrapper">
          <input id="trial_userSerch" type="text" [(ngModel)]="searchOne"
            (keyup)="searchFlows(($event.target['value']))" placeholder="{{'common.search_for' | translate}}"
            class="search__field " name="add_new_variant_question">
          <button id="trial_userSerchSub" type="submit" class="fa fa-search search__icon"></button>
        </div>
      </form>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive" style="padding:0;">
        <table aria-labelledby="contactList1" mat-table matSort class="contactTable" [dataSource]="whatsappFlows"
          style="overflow: hidden;">
          <ng-container matColumnDef="flow_name">
            <th id="flow_name" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'whatsapp_flow.flow_name' |
              translate}}
            </th>
            <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_name}}">
              {{element.flow_name ? element.flow_name : '-'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="flow_id">
            <th id="flow_id" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'whatsapp_flow.flow_id' |
              translate}}
            </th>
            <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_id}}">
              {{element.flow_id ? element.flow_id : '-'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="flow_end_response">
            <th id="flow_end_response" mat-header-cell *matHeaderCellDef i18n="@@matTab_name">
              {{'whatsapp_flow.flow_end_response' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_end_response}}">
              {{element.flow_end_response ? element.flow_end_response : '-'}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{'highlight': selecteUser.flow_id == row.flow_id}" (click)="showDetails(row)"
            (keydown)="showDetails(row)"></tr>
        </table>
      </div>
      <div class="row row-bottom" style="text-align:center;" *ngIf="( !whatsappFlows?.length) && !loadershow">
        <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate}}</p>
      </div>
      <app-pagination [collectionSize]="totalCount" [(page)]="pagelist" [maxSize]="10"
        (pageChange)="pageChange($event)"></app-pagination>
    </div>
  </div>
  <!--Right side section--> 
  <div *ngIf="isOpen" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 rightBar conversation-right-div" 
    [ngStyle]="{'display':checkWindowSize() ? 'none':'', 'width': (leftMargin !=='270px') ? '27%':''}"
    style=" overflow-x: hidden; height: 93vh">
    <div style="text-align:justify;">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display:inline-flex;">
        <h4 class="col-lg-9 col-md-9 col-sm-9 col-xs-9 viewHeading" style="display: block;margin: auto;margin-top:1px">
          <span>{{action}}</span>
          <span style="margin-left: 5px;">{{actionHeader}}</span></h4>
        <div *ngIf="action == 'View'" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 actionButton"
          style="float:right;padding:0px;top: 20px;height: 30px;">
          <button id="flowBuilderActionBtnLs" class="commonButton customButtonStyle dropdown-toggle lang-dropdown"
            style="width:100%;text-align:center;padding: 6px;display: inline-flex;font-size: 12px;font-family: 'WorkSansMedium', serif; height:30px;"
            type="button" data-toggle="dropdown">
            {{'common.ACTIONS' | translate}} &nbsp;&nbsp;<em class="fas fa-chevron-down" style="margin-top: 3%;"></em>
          </button>
          <ul class="dropdown-menu" style="margin-top:0px;">
            <li style="" class="actionMenuStyle" (click)="editFlow()" (keydown)="editFlow()">
              {{'common.edit' | translate}}
            </li>
          </ul>
        </div>
      </div>
      <form class="form-body">
        <div class="row rowbox" *ngIf="action == 'Update'" class="form-group" style="display: flex;text-align:left;">
          <div style="flex: 3;color: #828282;">
            Flow End Response
          </div>
          <div style="flex:6">
            <quill-editor style="width: 100%;" [ngModelOptions]="{standalone: true}" [(ngModel)]="parent_qa"
            [styles]="{ height: 'calc(35vh - 100px)' }"
              placeholder="Enter Text" [modules]="quillConfig" (onContentChanged)="updateParentQaContent($event);"
              #Editor>
              <div quill-editor-toolbar>
                <span class="ql-formats">
                  <button class="ql-bold" [title]="'Bold'"></button>
                  <button class="ql-italic" [title]="'Italic'"></button>
                  <button type="button" (click)="clickEmoji();">😀</button>
                  <button class="btn btn-default form-control headerTextEditorStyle" (click)="showSelectBox()"><em
                      class="fas fa-plus-circle" *ngIf="!showVariableSelectBox"></em>
                    <em class="fas fa-minus-circle" *ngIf="showVariableSelectBox"></em> {{'common.add_variable' |
                    translate}}
                  </button>

                  <br>
                  <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
                    title="Choose your emoji"></emoji-mart>
                </span>

                <div *ngIf="showVariableSelectBox">
                  <span class="question-form-label" *ngIf="showVariableSelectBox">{{'content_elements.variable_type' |
                    translate}}</span>
                  <label class="radio-inline question-form-label" *ngIf="showVariableSelectBox && projectVariable">
                    <input type="radio" id="project" style="margin-top: 1px;" [(ngModel)]="variableType"
                      name="radio_activity" value="project" (change)="onVariableRadioChange()" checked>
                    {{'common.project_defined' | translate}}
                  </label>
                  <label class="radio-inline question-form-label" *ngIf="showVariableSelectBox && systemVariable">
                    <input type="radio" id="system" style="margin-top: 1px;" name="radio_activity"
                      [(ngModel)]="variableType" value="system" (change)="onVariableRadioChange()">
                    {{'common.system_defined' | translate}}
                  </label>
                  <label class="radio-inline question-form-label" *ngIf="showVariableSelectBox && userVariable">
                    <input type="radio" id="user" style="margin-top: 1px;" name="radio_activity"
                      [(ngModel)]="variableType" value="user" (change)="onVariableRadioChange()">
                    {{'common.user_defined' | translate}}
                  </label>
                </div>
                <div style="width: 170px;" *ngIf="showVariableSelectBox">
                  <ngx-select-dropdown tabindex="0" style="text-overflow: ellipsis;" [multiple]="false"
                    (mouseover)="onHover($event)" title="{{titleDrop}}" [(ngModel)]="variableParam" id="variable3"
                    name="variable" [config]="variableConfig" [options]="VariabledropdownOptions"
                    (click)="onVariableClick()" (keydown)="onVariableClick()" (change)="onVariableChanged($event)"
                    [ngModelOptions]="{standalone: true}" (focus)="onFocus($event)"></ngx-select-dropdown>
                </div>
              </div>
            </quill-editor>
            <p *ngIf="parenterrormsg" style="color: red; font-size: 9px; margin-bottom: 0;">{{parenterrormsg}}</p>
          </div>
        </div>
        <div style="display: flex;text-align:left;" *ngIf="action == 'Update'">
          <div style="color: #828282;flex:3">
            {{'flowBuilder.choosefile' | translate}}
          </div>
          <div style="flex:6">
            <label for="file-input">
              <i class="fas fa-paperclip" aria-hidden="true"></i>
              <span>{{'common.Attach' | translate}}
                <em [popover]="popUpTemplatemultiple" [placement]="'right'" [container]="'body'" [outsideClick]="true"
                  class="fas fa-info-circle" triggers="mouseenter mouseleave click">
                </em>
              </span>
            </label>
            <span>
              <input #fileInputEdit id="file-input" type="file"
                accept="image/*,application/pdf,audio/*,video/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                class="custom-file-input" style="display: none;" (change)="editFileProcess($event)">
            </span>
          </div>
        </div>

        <div *ngIf="action == 'Update'">
          <div *ngFor="let imageDetail of imageDetailsList;">
            <div style="display: flex; padding: 7px 135px 0px;"
              *ngIf="imageDetail.url && imageDetail.mediaType == 'image'" class="img-wrap">
              <div style="width: 40%;"></div>
              <div style="width: 60%;margin-bottom: 14px;">
                <span (click)="removeImageOnClick(imageDetail.url);"
                  (keydown)="removeImageOnClick(imageDetail.url);" class="closeAttach"
                  style="cursor: pointer;">&times;</span>
                <img alt="" style="width: 60px;" [src]="imageDetail.url"
                  (click)="openFileInNewWindow(imageDetail.url)"
                  (keydown)="openFileInNewWindow(imageDetail.url)">
              </div>
            </div>
            <div style="display: flex; padding: 7px 135px 0px;align-items: center;"
              *ngIf="imageDetail.url && imageDetail.mediaType ==='document'" class="img-wrap">
              <div style="width: 40%;"></div>
              <div style="width: 60%; margin-bottom: 14px;">
                <span (click)="removeImageOnClick(imageDetail.url);"
                  (keydown)="removeImageOnClick(imageDetail.url);" class="closeAttach"
                  style="cursor: pointer;">&times;</span>
                <a [href]="imageDetail.url" target="_blank" style="width: 40px;">
                  <em class="fa fa-file-pdf-o" style="font-size:50px;"></em></a>
              </div>
            </div>
            <div style="display: flex; padding: 7px 135px 0px;"
              *ngIf="imageDetail.url && imageDetail.mediaType ==='video'" class="img-wrap">
              <div style="width: 40%;"></div>
              <div style="width: 60%;margin-bottom: 14px;">
                <span (click)="removeImageOnClick(imageDetail.url);"
                  (keydown)="removeImageOnClick(imageDetail.url);" class="closeAttach"
                  style="cursor: pointer;">&times;</span>
                <a [href]="imageDetail.url" target="_blank">
                  <em class="fa fa-file-video-o" style="font-size:50px;"></em></a>
              </div>
            </div>
            <div style="display: flex; padding: 7px 135px 0px;"
              *ngIf="imageDetail.url && imageDetail.mediaType ==='audio'" class="img-wrap">
              <div style="width: 40%;"></div>
              <div style="width: 60%;margin-bottom: 14px;">
                <span (click)="removeImageOnClick(imageDetail.url);"
                  (keydown)="removeImageOnClick(imageDetail.image_url);" class="closeAttach"
                  style="cursor: pointer;">&times;</span>
                <a [href]="imageDetail.url" target="_blank">
                  <em class="fa fa-file-audio-o" style="font-size:50px;"></em>
                </a>
              </div>
            </div>
            <div style="display: flex; padding: 7px 135px 0px;"
              *ngIf="imageDetail.url && imageDetail.mediaType ==='docx'" class="img-wrap">
              <div style="width: 40%;"></div>
              <div style="width: 60%;margin-bottom: 14px;">
                <span (click)="removeImageOnClick(imageDetail.url);"
                  (keydown)="removeImageOnClick(imageDetail.url);" class="closeAttach"
                  style="cursor: pointer;">&times;</span>
                <a [href]="imageDetail.url" target="_blank">
                  <em class="fa fa-file-word-o" style="font-size:50px;"></em></a>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 5px 5px; display: flex; justify-content: center;" *ngIf="uploadloader">
          <img alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 8%" />
        </div>
        <div style="display: flex;align-items: center;" *ngIf="action == 'Update'">
          <div style="color: #828282;flex:3;margin-top: 12px;">
            <span>{{'flowBuilder.send_action' | translate}}</span>
          </div>
          <div style="flex:6">
            <select class="form-control" style="margin-top: 12px;width: 100%;" id="node_type_1" [(ngModel)]="actionType"
              (ngModelChange)="processActionChange(actionType)" name="action_type">
              <option value="none">{{ 'flowBuilder.none' | translate}}</option>
              <option value="send_email">{{ 'flowBuilder.email_report' | translate}}</option>
              <option value="send_api">{{ 'flowBuilder.send_API' | translate}}</option>
              <option *ngIf="!agentHandover" value="transfer_to_agent">{{ 'flowBuilder.transfer_to_agent' | translate}}</option>
              <!-- <option value="connect_to_flows">{{ 'flowBuilder.Connect_to_flows' | translate}}</option> -->
              <option *ngIf="isPaymentEnabled" value="payment">{{ 'flowBuilder.create_payment' | translate}}</option>

            </select>
          </div>
        </div>

        <div *ngIf="actionType === 'transfer_to_agent' && action == 'Update'"
          style="display: flex;align-items: center;margin-top: 15px;">
          <div style="color: #828282;flex: 3">
            <span>{{'Agent Group'}}</span>
          </div>
          <div style="flex: 6">
            <select class="form-control" style="width: 100%;" id="agentHandover" [(ngModel)]="flowAgentGroup"
              name="AgentGroup">
              <option [ngValue]="agent?._id" selected="updateAgentGroup.group_name === agent.group_name"
                *ngFor="let agent of agentGroupList">
                {{ agent?.['group_name'] }}
              </option>
            </select>
            <p *ngIf="agentGrouperrormsg" style="color:red;font-size: 9px;margin-bottom: 0px;">{{agentGrouperrormsg}}
            </p>
          </div>
        </div>

        <div style="display: flex;align-items: center;margin-top: 15px;"
          *ngIf="(actionType === 'send_api'  && action === 'Update')">
          <div style="color: #828282;flex:6">
            <span>{{'flowBuilder.webhook_URL' | translate}}</span>
          </div>
          <div style="flex:12">
            <input class="form-control" style="width: 100%;padding:12px" type="text" name="webhookurl"
              value="webhookurl" placeholder="{{'flowBuilder.webhook_URL' | translate}}" [(ngModel)]="updatewebhookurl"
              (ngModelChange)="processActionChange(actionType)">
          </div>
          <div style="flex:1;margin-right: -2rem;">
            <em _ngcontent-qba-c163="" [popover]="popUpTemplate" [outsideClick]="true" triggers="click" placement="top"
              theme="light" class="fas fa-info-circle" style="float: right;"></em>
          </div>
        </div>

        <div style="display: flex;align-items: baseline;" *ngIf="(actionType === 'send_api' && action == 'Update')">
          <div style="color: #828282;flex: 3">
            <span>{{ 'common.header' | translate}}</span>
          </div>
          <div style="flex: 6;">
            <input class="form-control" style="width: 100%;padding:12px; margin-top: 12px;" type="text" name="headerkey"
              placeholder="{{'flowBuilder.header_Key_(Optional)' | translate}}" [(ngModel)]="updateheaderkey"
              (ngModelChange)="processActionChange(actionType)">
            <input class="form-control" style="width: 100%;padding:12px;margin-top: 15px;" type="text"
              name="headervalue" placeholder="{{'flowBuilder.header_Value_(Optional)' | translate}}"
              [(ngModel)]="updateheadervalue" (ngModelChange)="processActionChange(actionType)">
            <div style="display: flex;float: right;">
              <div style="flex:4;">
                <button class="commonButton nodeSubmit" (click)="validateApiUpdate()">{{'flowBuilder.validate' |
                  translate}}
                  <em class="fas fa-info-circle" tooltip="{{'flowBuilder.Validate_the_webhook_URL' | translate}}"
                    placement="top" theme="light"></em>
                </button>
              </div>
              <div style="flex:0; margin-top: 30px;" *ngIf="urlvalidation && validationClick">
                <em class="fa fa-times-circle" style="color:red; position: relative;"></em>
              </div>
              <div style="flex:0;margin-top: 30px;" *ngIf="!urlvalidation">
                <em class="fa fa-check-circle" style="color: green;position: relative;"></em>
              </div>
            </div>
          </div>
        </div>

        <form class="basic-container" [formGroup]="updateemailForm"
          *ngIf="(actionType === 'send_email' && action == 'Update')">
          <div formArrayName="updateemails">
            <div style="display: flex; align-items: center; margin-top: 2rem;"
              *ngFor="let updateemail of updateemailForm.get('updateemails')['controls']; let i = index"
              [formGroupName]="i">
              <div style="color: #828282;flex:4;margin-top: 3px;">
                <span class="mt-3">{{'common.email' | translate}}</span>
              </div>
              <div style="width: 65%;display: flex;flex:8">
                <div style="flex: 6">
                
                  <input class="form-control"
                    style="width: 100%; padding: 12px; border: 1px solid #ccc; border-radius: 4px; resize: vertical;margin-top: 4px;"
                    [ngStyle]="{'text-align':arabic_lan?'right':'left','direction': arabic_lan?'rtl':'ltr'}" type="text"
                    value="email" (keyup)="handleInput($event.target['value'], i)" formControlName="updateemailAddress"
                    placeholder="{{'common.email' | translate}}" autocomplete="email">
                  <mat-error *ngFor="let validation of updatevalidationMsgs.updateemailAddress; ">
                    <div style="text-align: left;" *ngIf="updateemail.get('updateemailAddress').hasError(validation.type) || emailerror[i]">
                      {{validation.message}}
                    </div>
                  </mat-error>
                  <p  *ngIf="!this.emailValue && emailValidationErr" style="color:#f44336; font-size: 15px; margin-bottom: 0;text-align: left;">{{emailValidationErr}}</p>
                </div>
                <div class="col-20 wrapper" style="flex: 1;display: flex;">
                  <div class="icon plus" (click)="updateaddEmailFormGroup('')" (keydown)="updateaddEmailFormGroup('')">
                    <div class="tooltip">{{'flowBuilder.add_email' | translate}}</div>
                    <span><em class="fas fa-plus"></em></span>
                  </div>
                  <div class="icon remove" (click)="updateremoveOrClearEmail(i)"
                    (keydown)="updateremoveOrClearEmail(i)">
                    <div class="tooltip">{{'flowBuilder.remove_email' | translate}}</div>
                    <span><em class="fas fa-times"></em></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div style="padding-bottom: 5px;" *ngIf="(actionType === 'payment' && isPaymentEnabled && action == 'Update')">
          <div style="display: flex; flex-direction: column; gap: 1rem; margin-top: 1rem;">

            <!-- Amount Section -->
            <div style="display: flex; align-items: center;">
              <div class="col-35" style="color: #828282;flex: 3">
                <span class="mt-3">{{'liveagent.amount' | translate}}</span>
              </div>
              <div class="col-60" style="flex: 6">
                <input type="number" min="0" [(ngModel)]="paymentSettings.amount" class="form-control"
                  [ngModelOptions]="{standalone: true}" />
                <p *ngIf="paymentErrormsg && !paymentSettings.amount"
                  style="color: red; font-size: 9px; margin-bottom: 0;">
                  {{paymentErrormsg}}
                </p>
              </div>
            </div>

            <!-- Currency Section -->
            <div style="display: flex; align-items: center;">
              <div class="col-35" style="color: #828282;flex: 3">
                <span>{{'liveagent.currency' | translate}}</span>
              </div>
              <div class="col-60" style="flex:6">
                <div ngbDropdown class="dropdown">
                  <button type="button" class="form-control btn btn-primary dropdown-toggle caret-off storageStyle"
                    style="background-color: white; height: 34px; width: 100%; border: 1px solid #ccc;"
                    id="paymentCountryMenu" ngbDropdownToggle>
                    <span style="float: left; font-weight: 400; font-size: 14px; color: #555555;">
                      {{paymentSettings?.currencyCode}}
                    </span>
                    <em class="fas fa-caret-down" style="margin-left: 5px; float: right; color: black;"></em>
                  </button>
                  <div style="max-height: 90px; overflow-y: scroll; padding: 5%; width: 100%;" class="dropdown-menu"
                    aria-labelledby="paymentCountryMenu" ngbDropdownMenu>
                    <div *ngFor="let country of selectedCurrencies">
                      <button type="button" class="form-control dropdown-item"
                        style="font-weight: 100; border: none; background-color: white; padding: 5px 0; width: 100%; text-align: left;"
                        (click)="changeSelectedCurrency(country)"
                        [ngClass]="{'selectedDropDownStyle': paymentSettings?.currencyCode === country.currency}">
                        {{country.currency}}
                      </button>
                    </div>
                  </div>
                  <em [popover]="popUpCurrency" [outsideClick]="true" triggers="mouseenter click" placement="top"
                    theme="light" class="fas fa-info-circle"
                    style="float: right; margin-top: 10px; margin-right: -2rem; cursor: pointer;">
                  </em>
                </div>
                <p *ngIf="paymentErrormsg && !paymentSettings?.currencyCode"
                  style="color: red; font-size: 9px; margin-bottom: 0;">
                  {{paymentErrormsg}}
                </p>
              </div>
            </div>

            <!-- Title Section -->
            <div style="display: flex; align-items: center;">
              <div class="col-35" style="flex:3 ;color: #828282;">
                <span>{{'common.title' | translate}}</span>
              </div>
              <div class="col-60" style="flex: 6">
                <input type="text" [(ngModel)]="paymentSettings.title" [ngModelOptions]="{standalone: true}"
                  placeholder="{{'liveagent.payTitle' | translate}}" class="form-control" />
                <p *ngIf="paymentErrormsg && !paymentSettings.title"
                  style="color: red; font-size: 9px; margin-bottom: 0;">
                  {{paymentErrormsg}}
                </p>
              </div>
            </div>

          </div>
        </div>

        <div style="display: flex; align-items: center;margin-top:15px;" *ngIf="(actionType === 'connect_to_flows' && action === 'Update')">
          <div style="flex:3;color: #828282;">
            <span >{{'flowBuilder.Choose_Flow' | translate}}</span>
          </div>
          <div style="flex:6">
            <ngx-select-dropdown class="form-control" tabindex="0" style="text-overflow: ellipsis;padding :0px" [multiple]="false" [(ngModel)]="updateConnectToFlow" id="variable3" name="name" [config]="guidedFlowConfig" [options]="guidedFlowsList"  [ngModelOptions]="{standalone: true}"></ngx-select-dropdown>
          </div>
        </div>

        <button
          [ngClass]="(urlvalidation) ? 'btnDisable submitButton' : 'commonButton flowUpdateSubmit'"
          (click)="updatewhatsAppFlow()" style="width: 40%;" *ngIf="action === 'Update'">
          {{'common.update' | translate}}</button>

        <button id="flows_Cancel" *ngIf="action === 'Update'" type="reset" class="cancelButton flowCancelSubmit"
          (click)="onCancel(); " style="width: 40%;">{{'common.cancel'|translate}}</button>

        <div *ngIf="action == 'View'">
          <span style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;"> {{'whatsapp_flow.flow_name'
            |
            translate}}</span>
          <span *ngIf="action == 'View'"><br /><span style="font-size: 14px;font-weight:500;">{{selecteUser.flow_name ?
              selecteUser.flow_name : ''}}</span></span>
          <br><br>
          <span *ngIf="action == 'View'" style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">
            {{'whatsapp_flow.flow_id' | translate}}</span>
          <span *ngIf="action == 'View'"><br />
            <span style="font-size: 14px;font-weight:500;">{{selecteUser.flow_id ? selecteUser.flow_id
              :''}}</span></span>
          <br><br>
          <span *ngIf="action == 'View'" style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">
            {{'whatsapp_flow.flow_end_response' | translate}}</span>
          <span *ngIf="action == 'View'"><br /><span style="font-size: 14px;font-weight:500;">
              {{selecteUser.flow_end_response ? selecteUser.flow_end_response :''}}</span></span>
          <br><br>
        <span *ngIf="action == 'View'" style="font-size: 12px; color: #828282; margin-bottom: 4px; font-weight: 500;">
            {{ 'whatsapp_flow.preview_url' | translate }}
          </span>
          <span *ngIf="action == 'View'">
            <br />
            <span *ngIf="selecteUser.preview_url" style="font-size: 14px; font-weight: 500; word-wrap: break-word; white-space: normal;">
              <a [href]="selecteUser.preview_url" target="_blank" style="color: inherit; text-decoration: underline; 
                      word-wrap: break-word; white-space: normal; overflow-wrap: anywhere;">{{ selecteUser.preview_url }}
              </a>
            </span>
          </span>
        </div>
      </form>
    </div>
  </div>

  <ng-template #popUpTemplatemultiple>
    <div class="card">
      <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
        <p class="card-text" style="font-size: 12px;" innerHtml="{{'content_elements.attachment_info' | translate}}">
        </p>
      </div>
    </div>
  </ng-template>
  <ng-template #popUpCurrency>
    <div class="card" style="border:none;max-height: 250px;width:250px;overflow-y: scroll;">
      <div class="card-body" style="padding: 0px !important;">
        <p class="card-text" style="font-size: 12px;" innerHtml="{{'common.currencyInfo' | translate}}">
        </p>
      </div>
    </div>
  </ng-template>
