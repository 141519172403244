<aside class="al-sidebar" [style.width]="sideBarWidth" [ngClass]="{'al-sidebar-width' : sideBarWidth ==='270px', 'al-sidebar-widthCollapsed' : sideBarWidth !=='270px'}"  (window:resize)="initResponsiveElements()" >
    <ul class="al-sidebar-list">
        <li class="al-sidebar-list-item ng-scope with-sub-menu"
            [ngClass]="{'ba-sidebar-expanded':(setActive == true)&&(menu.name == 'Content') }"
            routerLinkActive="ba-sidebar-item-expanded"
            *ngFor="let menu of menuItems"
            (click)="mainMenuCickToggle(menu)" (keydown)="mainMenuCickToggle(menu)">
            <a [routerLink]="dashboardPrefix + menu.path.toLowerCase()"
                class="al-sidebar-list-link ng-scope menu-expand-padding">
                <img class="menu-side-icon" [src]="getMenuIcon(menu.name)"  style="height :18px;width:18px;filter: invert(70%) sepia(1%) saturate(497%) hue-rotate(68deg) brightness(96%) contrast(87%);margin-left: 21px;" alt="" />
                <span class="ng-binding menu-content" style="margin-left: 21px;"> {{menu.label ? menu.label.toUpperCase() : menu.name.toUpperCase()}} </span>
            </a>
            
            <ul class="al-sidebar-sublist ng-scope" *ngIf="sideBarWidth ==='270px'">
                <li class="ba-sidebar-sublist-item ng-scope"
                    [ngClass]="{'ba-subsidebar-expanded': (submenu.name == 'Flow Builder' && submenu.setActive == true) || (submenu.name == 'Line Flex' && submenu.setActive== true) || (submenu.name == 'Rich menu' && submenu.setActive== true) }"
                    routerLinkActive="ba-sidebar-text-Conversation ba-sidebar-text-Questions"
                    *ngFor="let submenu of menu.sub">
                    <a [routerLink]="dashboardPrefix + submenu.path.toLowerCase()" (click)="menuToggle();$event.stopPropagation()"
                        ngClass="['ng-binding', 'ng-scope', submenu.name]">
                        {{submenu.label ? submenu.label.toUpperCase() : submenu.name.toUpperCase()}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    
        
    <div *ngIf="getVersion" style="margin-top: -12px;" [style.width]="sideBarWidth" [ngClass]="{'al-sidebar-width' : sideBarWidth ==='270px', 'al-sidebar-widthCollapsed' : sideBarWidth !=='270px'}">
        <div style="text-align: center" *ngIf="sideBarWidth !== '70px'"> 
            <img height="55" width="55" [src]="img_src" alt="">
            <div>{{'common.version' | translate}} {{getVersion}}</div>
        </div>
    </div>

</aside>
<span i18n="@@toastr_disengageWindows"  name="disengageWindows" style="display: none;">{{'common.disengage_active_chat' | translate}}</span>

