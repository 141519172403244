import { Component, OnInit, Renderer2 , Injector, ChangeDetectorRef} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Login } from './login';
import { LoginService } from './login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../../loader/loader.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EnvironmentService } from 'src/app/environment.service';
declare const google: any;
declare const window: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  user: Login = {
    username: '',
    password: '',
    browser_details: null,
    timeZone: '',
    lang: ''
  };
  error: { message: string; status: boolean } = {
    message: 'Sorry, Something went wrong.',
    status: false,
  };
  status: { status: string; token: any };
  dialogRef: MatDialogConfig;
  forgotPasswordSubmitted = false;
  email = '';
  validationMsg: any;
  submitted = false;
  showInfo = false;
  showLogin = true;
  clickValidate = false;
  passwordValidate = false;
  misMatch = false;
  config: any = {
    displayKey: 'title',
    search: true,
    height: 'auto',
    placeholder: 'Select',
    customComparator: () => {
      // Do Nothing
    },
    limitTo: 10,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'Language'
  };
  languageList: any = [
    {
    id: 'en',
    title: 'English'
    },
    {
    id: 'ar',
    title: 'Arabic'
    }
  ];
  defaultLanguage: any = 'en';
  UserForm: UntypedFormGroup;
  sharedService: SharedsService;
  loader: LoaderService;
  loginService: LoginService;
  translate: TranslateService;
  googleError = '';
  linkedinParams = {};
  linkedInToken: any;
  ssoEnabled = false;
  googleParams: { client_id: string; redirect_uri: string; };
  showSelfOnboard = false;
  selectedSignupOption = 'self';
  choosePlan = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    private dialog: MatDialog,
    private injector: Injector,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private envService: EnvironmentService) {
      this.ssoEnabled = this.envService.read('ssoEnabled');
      this.sharedService = this.injector.get<SharedsService>(SharedsService);
      this.loader = this.injector.get<LoaderService>(LoaderService);
      this.loginService = this.injector.get<LoginService>(LoginService);
      this.translate = this.injector.get<TranslateService>(TranslateService);
      this.translate.setDefaultLang('en');
      this.translate.use(this.defaultLanguage ? this.defaultLanguage : 'en');
      this.UserForm = this.formBuilder.group(
        {
          licensekey: ['', Validators.required],
          email: ['', Validators.required],
          company: ['', Validators.required],
          username: ['', Validators.required],
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        }, { validator: this.ConfirmPasswordValidator('password', 'confirm') }
      );
      this.renderer.listen('window', 'click', (e: Event) => {
        if (e.type === 'click') {
          if ((e.target as HTMLInputElement).id === 'signup') {
            // 
          } else if ((e.target as HTMLInputElement).id === 'forgetpassword') {
            this.showLogin = false;
          } else if ((e.target as HTMLInputElement).id === 'mainDiv') {
            this.updateVariable();
          } else if ((e.target as HTMLInputElement).className === 'cdk-overlay-backdrop cdk-overlay-dark-backdrop') {
            this.updateVariable();
          } else {
            // Do Nothings
          }
        }
      });
    }

  ngOnInit() {
    window.sendIdTokenToServer = this.sendIdTokenToServer.bind(this);
    this.setBrowserDetails();
    this.setSOSCredentials();
    this.handlePlanPayment();
    localStorage.setItem('Language', this.defaultLanguage);
  }
  setSOSCredentials() {
    if (this.ssoEnabled) {
      const uiURL = window.location?.origin || 'https://unified.ai';
      this.linkedinParams = {
        response_type: 'code',
        client_id: '86rwkxhx5lxdg8', // '86p95kwk79w4vo',
        redirect_uri:
          `${uiURL}/login`,
        state: 'linkedin',
        scope: 'openid, email', // Adjust scopes as needed
      };
      this.googleParams = {
        client_id: '914067849245-4fuulnaodra6ejquu4dlplg6e6evh3ci.apps.googleusercontent.com',
        // client_id: "640604431937-f8a1qtodr1r8iehu514l0m1mpcetets1.apps.googleusercontent.com", // NOSONAR
        redirect_uri:
          `${uiURL}/login`,
      };
      const code = this.route.snapshot.queryParams['code'];
      const state = this.route.snapshot?.queryParams?.state;
      if (state === 'google') {
        this.sendIdTokenToServer(code);
      } else if (state === 'linkedin') {
        this.linkedInToken = code;
        this.validateLinkedInLogin();
      }
    }
  }

  updateVariable() {
    this.showLogin = true;
    this.showInfo = false;
  }
  sign_in(user: Login): void {
    user.username = user.username.trim().toLowerCase();
    user.password = user.password.trim();
    user.timeZone = this.sharedService.timeZone;
    user.lang = this.defaultLanguage;
    this.user = user;
    this.loginService.login(this.user).subscribe((data) => {
      this.loader.display(false);
      this.error.status = false;
      if (data?.success) {
        this.handleLoginSignUp(data);
      } else {
        this.error.status = true;
        this.error.message = data.message
          ? data.message
          : this.translate.instant('toastr.something_wrong');
        this.toastr.error('', this.error.message);
      }
    });
  }

  handleLoginSignUp(data) {
    this.setValues(data);
    const redirectUrl = this.loginService.redirectUrl ? this.loginService.redirectUrl : environment.dashboardPrefix + data.user_data.roles.menu_tab[0].path;
    const userData = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    const channels = userData.channels ? userData.channels : '';
    let request_from_apple = false;
    if (userData ?. appSettings ?. projectDetail ?. request_from_apple) {
      request_from_apple = userData ?. appSettings ?. projectDetail ?. request_from_apple;
    }
    if (
      data.user_data.multiAppLogin ||
      (channels && channels.length === 0 && request_from_apple)
    ) {
      this.router.navigate(['apps']);
    } else {
      this.status = {
        status: 'active',
        token: data.user_data.token,
      };
      this.sharedService.AgentOnlineStatus(this.status).subscribe((response) => {
      });
      this.router.navigate([redirectUrl]);
    }
  }
  setValues(data) {
    const theme = data.user_data?.['appSettings']?.['projectDetail']?.theme;
    localStorage.setItem('theme', theme || 'black-style.css');
    sessionStorage.setItem('loggedin', 'true');
    const allowedAppIds = data.user_data?.appid;
    if (data.user_data?.appid?.length > 0) {
      data.user_data.appid = data.user_data.appid[0];
    }
    data.user_data.wabaAccounts = data?.user_data?.['appSettings'][
      'projectDetail'
    ]['wabaAccounts']
      ? data.user_data['appSettings']['projectDetail']['wabaAccounts']
      : [];
    localStorage.setItem('user', JSON.stringify(data.user_data));
    localStorage.setItem('allowedAppIds', allowedAppIds);
    if (data.user_data.companyNameLogoUrl) {
      localStorage.setItem(
        'companyNameLogoUrl',
        JSON.stringify(data.user_data.companyNameLogoUrl)
      );
    }
    if (data.user_data.companyName) {
      localStorage.setItem(
        'companyName',
        JSON.stringify(data.user_data.companyName)
      );
    }

    localStorage.setItem(
      'access-token',
      JSON.stringify(data.user_data.token)
    );

    this.sharedService.appId = data?.user_data?.appid ?? '';
    this.sharedService.token = data?.user_data?.token ?? '';


    sessionStorage.setItem('appid', this.sharedService.appId);

    if (data.user_data.companyNameLogoUrl) {
      localStorage.setItem(
        'companyNameLogoUrl',
        JSON.stringify(data.user_data.companyNameLogoUrl)
      );
    }
    if (data.user_data.companyName) {
      localStorage.setItem(
        'companyName',
        JSON.stringify(data.user_data.companyName)
      );
    }
    if (data.user_data.name) {
      localStorage.setItem('name', JSON.stringify(data.user_data.name));
    }
    if (data.user_data.address) {
      localStorage.setItem('address', JSON.stringify(data.user_data.address));
    }
  }
  setBrowserDetails() {
    this.user.browser_details = this.deviceService.getDeviceInfo();
  }

  showTemplateSignUp() {
    this.selectedSignupOption = 'self';
    this.showSelfOnboard = true;
    this.choosePlan = true;
  }
  showTemplate(TemplateRef) {
    this.showLogin = false;
    this.dialogRef = this.dialog.open(TemplateRef, {
      panelClass: 'dialog-container-custom',
      width: '650px !important',
    });
  }

  submitForgotPassword() {
    this.loader.display(true);
    this.forgotPasswordSubmitted = true;
    if (this.email === '' || this.email === null) {
      this.loader.display(false);
      return;
    }

    const args = {
      email: this.email.trim(),
    };
    this.loginService.forgotPassword(args).subscribe(
      (data) => {
        if (data.status === 404) {
          this.loader.display(false);
          this.toastr.error(data.info);
          this.email = '';
          this.forgotPasswordSubmitted = false;
        } else if (data.status !== 200) {
          this.toastr.error(
            this.translate.instant('toastr.error_resetlink_sent')
          );
          this.loader.display(false);
          this.email = '';
          this.forgotPasswordSubmitted = false;
        } else {
          this.toastr.success(
            this.translate.instant('toastr.resetlink_sent')
          );
          this.loader.display(false);
          this.email = '';
          this.forgotPasswordSubmitted = false;
          this.dialog.closeAll();
        }
        this.showLogin = true;
      },
      (error) => {
        this.toastr.error(
          this.translate.instant('toastr.error_passwordLink')
        );
        this.loader.display(false);
        this.email = '';
        this.forgotPasswordSubmitted = false;
        this.showLogin = true;
      }
    );
  }

  onCancel() {
    this.dialog.closeAll();
    this.showLogin = true;
    this.email = '';
    this.forgotPasswordSubmitted = false;
  }
  changeLanguage(event) {
    this.defaultLanguage = event.value.id;
    localStorage.setItem('Language', this.defaultLanguage);
    this.translate.use(event.value.id);
  }

  licenseValidation() {
    this.clickValidate = true;
    if (this.UserForm?.controls['licensekey'].invalid) {
      return;
    }
    const key = {
      token: this.UserForm?.controls['licensekey'].value
    };
    this.loginService.licenseValidation(key).subscribe((data) => {
      if (data?.status && Object.keys(data.info).length) {
        this.showInfo = true;
        this.clickValidate = false;
        this.setValidationinfo(data);
      } else {
        this.validationMsg = data.info;
        this.showInfo = false;
        this.clickValidate = true;
      }
    });
  }

  setValidationinfo(data) {
    this.UserForm?.controls['licensekey'].disable();
    this.UserForm?.controls['email'].setValue(data.info.email ? data.info.email : '');
    this.UserForm?.controls['username'].setValue(data.info.email ? data.info.email : '');
    this.UserForm?.controls['company'].setValue(data.info.company ? data.info.company : '');
  }

  verifyKey() {
    this.validationMsg = '';
  }

  onClose() {
    this.dialog.closeAll();
    this.showInfo = false;
    this.showLogin = true;
    this.UserForm?.controls['licensekey'].enable();
    this.UserForm?.reset();
    this.clickValidate = false;
    this.submitted = false;
    this.validationMsg = '';
  }

  onSignUp() {
    this.submitted = true;
    const browser_details  = this.deviceService.getDeviceInfo();
    if (this.UserForm?.invalid) {
      return;
    }
    const info = {
      company: this.UserForm?.controls['company'].value,
      userName: this.UserForm?.controls['username'].value,
      password: this.UserForm?.controls['password'].value,
      token: this.UserForm?.controls['licensekey'].value,
      browser_details : browser_details
    };
    this.loginService.userSignup(info).subscribe((response) => {
      this.signUpApiResp(response, '');
    });
  }
  signUpApiResp(response, status) {
      if (response && response.status === true) {
        this.toastr.info('', this.translate.instant('toastr.account_created'));
        this.setValues(response.data);
        localStorage.setItem('channelDetails', JSON.stringify(response.data.channelDetails));
        localStorage.setItem('developerId', JSON.stringify(response.data.developerId));
        if(status === 'selfOnboard') {
          this.router.navigate([environment.dashboardPrefix + '/settings'], { state: { action: 'self-onboard' } });
        } else {
          this.router.navigate([environment.dashboardPrefix + '/settings'], { state: { action: 'license-key' } });
        }
        this.dialog.closeAll();
        this.submitted = false;
        this.showLogin = false;
      } else {
        this.toastr.error(response?.info);
        this.loader.display(false);
        this.onClose();
      }
  }
  ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmPasswordValidator) {
        this.passwordValidate = true;
        return;
      }
      if (control.value !== matchingControl.value) {
        this.passwordValidate = true;
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        this.passwordValidate = false;
        matchingControl.setErrors(null);
      }
    };
  }

  sendIdTokenToServer(googleUser) {
    this.googleError = '';
    const idToken = googleUser;
    const arg = { idToken };
    if (idToken) {
      this.loginService.sendGoogleToken(arg).subscribe((data) => {
        this.error.status = false;
        if (data?.success) {
          this.loader.display(false);
          this.handleLoginSignUp(data);
        } else {
          const error = data.message
            ? data.message
            : 'Sorry something went wrong';
            this.toastr.error('', error);
            console.log('error', error);
            this.googleError = error;
            this.loader.display(false);
            this.cdr.detectChanges();
            this.router.navigate(['.'], { relativeTo: this.route, queryParams: { }});
        }
      });
    } else {
      this.loader.display(false);
      this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      this.cdr.detectChanges();
    }
  }
  validateLinkedInLogin() {
    console.log('Login linkedInToken', this.linkedInToken);
    this.loader.display(true);
    this.loginService.sendLinkedinToken({ code: this.linkedInToken }).subscribe((data) => {
      this.loader.display(false);
      this.error.status = false;
      if (data?.success) {
        this.handleLoginSignUp(data);
      } else {
        this.router.navigateByUrl('/login');
        const error = data.message
          ? data.message
          : this.translate.instant('toastr.something_wrong');
        this.googleError = error;
        this.toastr.error('', error);
      }
    });
  }
  loginUsingLinkedIn() {
    window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${this.linkedinParams['client_id']}&redirect_uri=${this.linkedinParams['redirect_uri']}&state=${this.linkedinParams['state']}&scope=email,openid`;
  }
  loginUsingGoogle() {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${this.googleParams['client_id']}&redirect_uri=${this.googleParams['redirect_uri']}&response_type=code&scope=profile email&state=google&prompt=select_account`;
  }
  handlePlanPayment() {
    const sessionId = this.route.snapshot?.queryParamMap.get('session_id');
    const paymentStatus = this.route.snapshot?.queryParamMap.get('payment');
    const onboardId = this.route.snapshot?.queryParamMap.get('onboardId');
    const arg = {
      status: paymentStatus,
      checkout_session_id: sessionId,
      onboardId: onboardId
    }
    if (sessionId && paymentStatus === 'success' && onboardId) {
      this.verifyPayment(arg);
    } else if(paymentStatus === 'cancel' && onboardId) {
      this.verifyPayment(arg);
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { }});
    }
  }
  verifyPayment(arg) {
    this.loader.display(true);
    this.loginService.oboardSignup(arg).subscribe({
      next: (data) => {
        this.signUpApiResp(data, 'selfOnboard');
      },
      error: (err) => {
        console.error('Error flistPlan:', err);
      },
    })
  }
  closeAttach() {
    this.showSelfOnboard = false;
    this.choosePlan = false;
  }
  handleReceivedData(data) {
    this.showSelfOnboard = data;
  }
  selectedSignupChange(value) {
    this.selectedSignupOption = value;
  }
}
