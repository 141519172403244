import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
declare let Swal: any;

@Component({
  selector: 'app-rag',
  templateUrl: './rag.component.html',
  styleUrls: ['./rag.component.scss']
})
export class RagComponent {
  isLoading = null;
  loadershow = false;
  imageUploadStatus = false;
  selectedRAGTab = 'document';
  selectedFile1: any = '';
  uploadLoader = false;
  uploadedFile = false;
  userSubscription1: Subscription;
  userSubscription2: Subscription;
  userSubscription3: Subscription;
  docName = '';
  documentUrl: any;
  uploadCheck = false;
  uploadedFiles: any[] = [];
  uploadedUrlFiles: any[] = [];
  urlName = '';
  uploadUrlLoader = false;
  uploadUrlFile = false;
  uploadUrlCheck = false;
  projectId: any;
  documentFiles: any;
  websiteFiles: any;
  allDocumentKeys: any;
  deleteFlag = false;
  ragList: any;
  istraining: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  rag: any = {
    ragEnabled: '',
  };
  ragEnabled = false;
  toastr: ToastrService;
  _settingsService: SettingsService;
  translate: TranslateService;


  constructor(private readonly injector: Injector,
    private readonly http: HttpClient) {
      this.toastr = this.injector.get<ToastrService>(ToastrService);
      this._settingsService = this.injector.get<SettingsService>(SettingsService);
      this.translate = this.injector.get<TranslateService>(TranslateService);
      this.translate.setDefaultLang('en');

    }
    ngOnInit() {
      this.listAllUrlFiles();
    }


  selectedRAGChange(value) {
    this.selectedRAGTab = value;
  }


  handleFileUpload(event: any): void {
    
    this.selectedFile1 = event.target.files[0];
    const reader1 = new FileReader();

    reader1.readAsBinaryString(this.selectedFile1);

    reader1.onload = async (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const pageCount = Data.match(/\/Type\s*\/Page\b/g)?.length;
      if (pageCount > 250) {
        this.toastr.error('page count is above 250');
        this.selectedFile1 = '';
      } else if (event?.target?.files?.length && event.target.files[0].type !== 'application/pdf') {
        this.selectedFile1 = '';
        this.toastr.error('Please upload valid pdf document');
      } else if (this.selectedFile1.size > 31457280) {
        this.selectedFile1 = '';
        this.toastr.error('Maximum file size must be less than 30MB');
        this.fileInput.nativeElement.value = ''; 
      } else {
        this.uploadLoader = true;
        this.uploadedFile = true;
        this.uploadFile(event);
      }
    };
  }


  uploadFile(event) {
    if(this.deleteFlag) {
      return;
    }
    const appUser = JSON.parse(localStorage.getItem('user'));
    const Editfiles = event.target.files;
    const file = Editfiles[0];
    const file2 = Editfiles[0]?.name;
    const pattern = /pdf-*/;
    const reader = new FileReader();
  
    reader.readAsBinaryString(file);
  
    reader.onload = async (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file.type,
        filename: file2,
        appId: appUser.appid,
        type: 'document',
      };
      this.deleteFlag = true;
      this.userSubscription1 = this._settingsService.getFiles(args).subscribe(
        (res) => {
          this.deleteFlag = false;
          // Check if the status is 500 in the response
          if (res?.info?.connectionStatus === 500) {
            this.toastr.error(
              '',
              this.translate.instant('toastr.error_msg_delete')
            );
              this.listAllUrlFiles();
            this.documentUrl = res.info.documentUrl;
            this.uploadLoader = false;
            this.selectedFile1 = '';
            this.emptyFileInput();
            return;
          }
  
          // Handle successful response
          if (res) {
            console.log('this.docName', this.docName);
            this.selectedFile1 = '';
            this.uploadLoader = false;
            console.log('resss', res.info);
            console.log('connectionStatus', res.info.connectionStatus);
            this.listAllUrlFiles();
            this.documentUrl = res.info.documentUrl;
            this.uploadedFile = true;
            this.uploadCheck = true;
            this.emptyFileInput();
  
            // Push relevant data into the array
            this.uploadedFiles.push({
              filename: file2,
              documentUrl: res.info.documentUrl,
            });
  
            this.toastr.success('Document uploaded successfully');
          }
  
          if (!file.type.match(pattern)) {
            this.toastr.error('', this.translate.instant('toastr.invalid_format'));
            return false;
          }
        },
        (error) => {
          // Handle errors
          this.deleteFlag = false;
          console.error('File upload failed:', error);
          if (error.status === 500) {
            this.toastr.error(
              '',
              this.translate.instant('toastr.error_msg_delete')
            );
            } else {
            this.toastr.error('An error occurred during file upload.');
          }
          this.uploadLoader = false; 
          this.selectedFile1 = ''; 
        }
      );
    };
  }

  train() {
    if(this.deleteFlag) {
      return;
    }
    if (this.urlName) {
      // Regular expression for URL validation
      const urlRegex = /^(https?:\/\/|www\.)[^ "]+$/;
      if (urlRegex.test(this.urlName)) {
        console.log('Valid URL:', this.urlName);
        // Proceed with uploading and training
        this.uploadUrlLoader = true;
        const appUser = JSON.parse(localStorage.getItem('user'));
        const args = {
          data: this.urlName,
          contentType: 'text',
          filename: this.urlName,
          appId: appUser.appid,
          type: 'website'
        };
        this.deleteFlag = true;
        this.userSubscription2 = this._settingsService
          .getFiles(args)
          .subscribe((res) => {
            if (res) {
              this.deleteFlag = false;
              if (res.info.connectionStatus === 200) {
                this.urlName = '';
                this.listAllUrlFiles();
                this.documentUrl = res.info.documentUrl;
                this.uploadUrlFile = true;
                this.uploadUrlCheck = true;
                // Push relevant data into the array
                this.uploadedUrlFiles.push({
                  filename: this.urlName,
                  documentUrl: res.info.documentUrl
                });
                this.toastr.success('Url uploaded successfully');
                console.log(this.uploadedUrlFiles);
              } else if (res.info.connectionStatus === 500) {
                this.toastr.error(
                  '',
                  this.translate.instant('toastr.error_msg_delete')
                );               } else {
                this.toastr.error('Url already trained');
              }
              this.urlName = '';
              this.uploadUrlLoader = false;
            }
          });
      } else {
        this.deleteFlag = false;
        this.toastr.error('Invalid URL');
        console.log('Invalid URL:', this.urlName);
      }
    }
  }

  onKey(event: any) {
    this.urlName = event.target.value;
  }
  onKeyDocument(event: any) {
    this.docName = event.target.value;
    console.log('this.docName1', this.docName);
  }


  listAllUrlFiles() {
    this.userSubscription3 = this._settingsService
      .getTrailAccountDetails()
      .subscribe((res) => {
        if (res?.info?.ragDocuments) {
          this.projectId = res.info._id;
          // Separate arrays for documents and websites
          this.documentFiles = [];
          this.websiteFiles = [];
          this.allDocumentKeys = [];
          // Iterate over the ragDocuments array
          res.info.ragDocuments.forEach((document, index) => {
            if (document.type === 'document') {
              // Add document to the documentFiles array
              this.documentFiles.push(document);
              console.log(this.documentFiles, 'documentFiles');
            } else if (document.type === 'website') {
              // Add website to the websiteFiles array
              this.websiteFiles.push(document);
            }
            const documentKeyValues = Object.keys(document).map((key) => {
              return { key, value: document[key] };
            });
            this.allDocumentKeys.push({ documentIndex: index, keyValues: documentKeyValues });
            console.log(`Document ${index + 1}:`, documentKeyValues);
          });         
          this.ragList = res.info.ragDocuments.length;
          this.istraining = this.documentFiles.some(item=>item.trainingStatus === undefined || item.trainingStatus === null)
          this.ragEnabled = res.info.ragEnabled;
          console.log('All Document Keys:', this.documentFiles);
        }
      });
  }

  deleteRagDocument(ragDocumentId: string, item: any) {
    const args = {
      projectId: this.projectId,
      ragDocumentId: ragDocumentId
    };
    this.deleteFlag = true;
    this.isLoading = ragDocumentId;
    this._settingsService.deleteRagDocument(args).subscribe(
      (response) => {
        this.deleteFlag = false;
        this.isLoading = null;
        if (response.connectionStatus === 200) {
          if (item.trainingStatus === true) {
            this.toastr.success(
              '',
              this.translate.instant('toastr.deleted_document')
            );
          } else {
            this.toastr.success(
              '',
              this.translate.instant('toastr.error_delete_file')
            );
          }
        } else {
          this.toastr.error(
            '',
            this.translate.instant('toastr.error_msg_delete')
          );
        }
        this.listAllUrlFiles();
      },
    );
  }
  emptyFileInput() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  openConfirmationDialog(ragDocumentId: string, item: any) {
    if(this.deleteFlag || this.istraining) {
      return;
    }
    Swal.fire({
      title:
      '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to delete this?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.deleteRagDocument(ragDocumentId, item);
      }
    });
  }

}
