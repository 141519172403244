import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { HomeService } from './home.service';
import { SocketService } from './socket.service';
import { Subscription, Subject, filter } from 'rxjs';
import { MsgCenterService } from './navbar/msg-center/msg-center.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material/dialog';
import { SelfIntroComponent } from './self-intro/self-intro.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  permission: any;

  constructor(
    private readonly homeService: HomeService,
    private readonly socket: SocketService,
    private readonly _msgCenterService: MsgCenterService,
    private readonly sharedService: SharedsService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
    const userData = JSON.parse(localStorage.getItem('user'));
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');

    this.inActiveTime = userData?.appSettings?.projectDetail?.inactive_time_limit ? userData.appSettings.projectDetail.inactive_time_limit : 60;
    this.subscription = this.homeService.getMessage().subscribe((data) => {
      if (data.type === 'sideBar') {
        this.leftMargin = data.message ? '270px' : '70px';
      }
    });
    this.setTimeout();
    this.userInactive.subscribe(() => {
    });
    this.router.events.subscribe(() => {
      this.showSideMenu = false;
    });

  }
  screenWidth: number;
  sideBarToggle: boolean;
  removeContentFlag: boolean;
  enableAddButton = true;

  leftMargin = '270px';
  subscription: Subscription;

  userActiveStatus = true;
  userActivity;
  translatorLanguage: any;
  userInactive: Subject<any> = new Subject();

  awayStatus = { status: 'away' };
  activeStatus = { status: 'active' };
  inActiveTime = 60;
  showSideMenu = false;
  plusButtonMenuList = [];
  userType: any;
  userId: any;
  // for getting sidebar size from sidebar component.
  receiveMessage($event) {
    this.sideBarToggle = $event;
  }

  setTimeout() {
    if (!this.userActiveStatus) {
      this.userActiveStatus = true;
      this.sharedService
        .AgentOnlineStatus(this.activeStatus)
        .subscribe((response) => {
          this._msgCenterService.editAgentOnlineFlag('true');
        });
    }
    this.userActivity = setTimeout(() => {
      this.sharedService
        .AgentOnlineStatus(this.awayStatus)
        .subscribe((response) => {
          this._msgCenterService.editAgentOnlineFlag('false');
        });
      this.userActiveStatus = false;
      this.userInactive.next(null);
    }, this.inActiveTime * 60000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('document:keypress') refreshUserStates() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('window:beforeunload') onWindowClose() {
    this.sharedService.AgentOnlineStatus(this.awayStatus);
  }

  setStatusAway() {
    this.sharedService.AgentOnlineStatusAsync2();
  }

  ngOnInit() {
    const r = this.router.url.split('/');
    if (r[2] === 'flow-builderv3') {
      this.enableAddButton = false;
    } else {
      this.enableAddButton = true;
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event?.url.includes('/dashboard/flow-builderv3')) {
        this.enableAddButton = false;
      } else {
        this.enableAddButton = true;
      }
    });
    this.showSideMenu = false;
    this.socket.connect();
    this.screenWidth = window.innerWidth;
    this.initResponsiveElements();
    const user = JSON.parse(localStorage.getItem('user'));
    this.permission = user?.appSettings?.permissions ? user.appSettings.permissions : true;
    this.userType = user.user_type ? user.user_type : '';
    this.userId = user.user_id ? user.user_id : '';
    this.getButtonMenuList();
    const userData = JSON.parse(localStorage.getItem('user'));
    const sessionAppid = sessionStorage.getItem('appid');
    const loggedIn = sessionStorage.getItem('loggedin');
    if (!_.isEmpty(userData) && !sessionAppid && loggedIn !== 'true') {
      // alredy logggedin user in browser
      const loginData = {
        user_id: userData.user_id,
        username: userData.name,
        browser_details: this.deviceService.getDeviceInfo(),
        login_status: true,
      };
      this.sharedService.updateLoginHistory(loginData).subscribe((response) => {
        if (response) {
          sessionStorage.setItem('loggedin', 'true');
        }
      });
    }
    const botId = user.appSettings.projectDetail?.Bot_ID ?? [];
    if (!botId.length && !this.router.url.includes('settings')) {
        this.openIntroDialog();
    }
  }

  onActivate(event) {
    const scrollToTop = window.setInterval(() => {
      const pos = window.scrollY;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  initResponsiveElements() {
    this.screenWidth = window.screen?.width;
    if (this.screenWidth < 600) {
      this.removeContentFlag = true;
    } else {
      this.removeContentFlag = false;
    }
  }

  getButtonMenuList() {
    const args = { appid: '', usertype: '', userid: '' , lang: ''};
    const lang = localStorage.getItem('Language');
    args.appid = this.sharedService.appId;
    args.usertype = this.userType;
    args.userid = this.userId;
    args.lang = lang;
    this._msgCenterService.getAppDetails(args).subscribe((result) => {
      if (result.success === true) {
        this.plusButtonMenuList = result?.user_data?.createMenuSubList?.createMenuSubList ?? [];
        if (!this.permission.qn_add) {
          const index = this.plusButtonMenuList.findIndex(a => a.plusMenulabel === 'create_question');
          if (index !== -1) {
            this.plusButtonMenuList.splice(this.plusButtonMenuList.findIndex(a => a.plusMenulabel === 'create_question') , 1);
          }
        }
      } else {
        this.plusButtonMenuList = [];
      }
    });
  }

  toggleBottomMenu(event) {
    event.preventDefault();
    this.showSideMenu = !this.showSideMenu;
  }

  menuClick(path) {
    this.showSideMenu = false;
    if (path.toLowerCase() === '/conversationalelements') {
      this.sharedService.contentElementClickedFromHome = true;
      this.router.navigate([environment.dashboardPrefix + '/conversationalelements']);
    } else {
      this.router.navigate([environment.dashboardPrefix + path]);
    }

    //   case 'messageList':
    //     this.router.navigate([environment.dashboardPrefix+'/setmessagelist']);
    //     break;
    //   case 'conversationElements':
    //     this.sharedService.contentElementClickedFromHome = true;
    //     this.router.navigate([environment.dashboardPrefix+'/conversationalelements']);
    //     break;
    //   case 'messageTemplates':
    //     this.router.navigate([environment.dashboardPrefix+'/templates']);
    //     break;
    //   case 'broadcast':
    //     this.router.navigate([environment.dashboardPrefix+'/broadcastmessage']);
    //     break;
    //   default:
    //     break;
    // }
  }
      openIntroDialog() {
          this.dialog.open(SelfIntroComponent, {
              width: '500px',
              data: {}
          });
      }
}
