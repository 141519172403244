import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './dashboard/login/login.component';
import { AppselectorComponent } from './dashboard/appselector/appselector.component';
import { RegisterComponent } from './dashboard/register/register.component';
import { SetpasswordComponent } from './dashboard/home/setpassword/setpassword.component';
import { DigitalAssistantComponent } from './dashboard/digital-assistant/digital-assistant.component';
import { SignupComponent } from './dashboard/signup/signup.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { title: 'Register' },
  },
  { path: 'apps', component: AppselectorComponent, data: { title: 'Apps' } },
  {
    path: 'setpassword',
    component: SetpasswordComponent,
    data: { title: 'Set Password' },
  },
  {
    path: 'aiagent',
    component: DigitalAssistantComponent,
    data: { title: 'Ai agent' },
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: { title: 'Sign Up' },
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' },
        )
    ],
    exports: [RouterModule],
    declarations: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {
  static forRoot(arg0: undefined[]): any {
    throw new Error('Method not implemented.');
  }
}
