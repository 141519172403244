<app-page-header id="flowResponseHead" pageTitle="Flow Responses" EngTitle="flow-responses"></app-page-header>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
    <div class="page-loader" *ngIf="loaderShow">
        <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
    </div>
    <form class="search">
      <div class="search__wrapper" style="padding-left: 8px; padding-right: 43px;">
        <input 
          id="trial_userSerch" 
          type="text" 
          [(ngModel)]="searchOne" 
          (keyup)="onSearchChange(searchOne)"
          placeholder="{{'common.search_for' | translate}}" 
          class="search__field"
          name="add_new_variant_question">
        <button 
          id="trial_userSerchSub" 
          type="button" 
          class="fa fa-search search__icon" 
          style="right: 45px;" 
          (click)="triggerSearch()">
        </button>
      </div>
    </form>
</div>

<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <div class="col-md-10 col-lg-6 col-sm-12 col-xs-4 mat-radio-div" *ngIf="showParentTable">
    <mat-radio-group [(ngModel)]="flowInfo">
      <mat-radio-button 
        *ngIf="isWhatsAppAvailable" 
        value="all" 
        style="margin-bottom: 12px;"  
        (change)="getAllFlowList('all')">
        <h4 style="margin-top: 12px;" i18n="@@recipient">
          {{ 'flow_responses.all' | translate }}
        </h4>
      </mat-radio-button>
      <mat-radio-button 
        *ngIf="isWhatsAppAvailable" 
        value="WhatsApp" 
        style="margin-bottom: 12px; margin-left: 15px;"  
        (change)="getAllFlowList('WhatsApp')">
        <h4 style="margin-top: 12px;" i18n="@@recipient">
          {{ 'flow_responses.whatsapp_flows' | translate }}
        </h4>
      </mat-radio-button>
      <mat-radio-button 
     
        value="UIB" 
        style="margin-bottom: 12px; margin-left: 15px;"  
        (change)="getAllFlowList('UIB')">
        <h4 style="margin-top: 12px;" i18n="@@recipient">
          {{ 'flow_responses.uib_flows' | translate }}
        </h4>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  
  
   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flow-table" *ngIf="showParentTable">
      <table aria-labelledby="contactList1" mat-table matSort class="contactTable" [dataSource]="dataOne"
             style="overflow: hidden;">
             <ng-container matColumnDef="flow_name">
                <th id="flow_name" mat-header-cell *matHeaderCellDef i18n="@@matTab_name">{{'flow_responses.flow_name' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_name}}">
                   {{element.flow_name ? element.flow_name : '-'}}
                </td>
             </ng-container>
             <ng-container matColumnDef="flow_type">
                <th id="flow_type" mat-header-cell *matHeaderCellDef i18n="@@matTab_name">{{'flow_responses.flow_type' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_type}}">
                  <span *ngIf="element.flow_type == 'WhatsApp'">{{ 'flow_responses.whatsapp' | translate }} </span>
                  <span *ngIf="element.flow_type == 'UIB'">{{'flow_responses.uib' | translate}}</span>
                  <span *ngIf="element.flow_type != 'UIB' && element.flow_type != 'WhatsApp'"> - </span>
                </td>
             </ng-container>
             <ng-container matColumnDef="created_on">
              <th id="created_on" mat-header-cell *matHeaderCellDef i18n="@@matTab_name">
                {{ 'flow_responses.created_on' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="cursor: default;"
                [title]="element.created_on | date: dateFormat + ' h:mm a'">
                {{ element.created_on | date: dateFormat }} &nbsp;{{ element.created_on | date: 'h:mm a' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Flowresponsecount">
              <th id="flow_end_response" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'flow_responses.Flowresponsecount' | translate}}
              </th>
              <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.Flowresponsecount}}">
                 {{element.Flowresponsecount ? element.Flowresponsecount : '-'}}
              </td>
           </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns" style="cursor: context-menu;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="routeToFlows($event, row)" (keydown)="routeToFlows($event, row)" tabindex="0">
              </tr>
          </table>
          <div class="row row-bottom" style="text-align:center;" *ngIf="( !dataOne?.length) && !loadershow">
            <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate}}</p>
          </div>
          <app-pagination [collectionSize]="totalFlows" [noPerPage]="itemsPerPage" [(page)]="pagelist" [maxSize]="3" 
          (pageChange)="pageChange($event)"></app-pagination>
   </div>
   <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 flow-table" style=" padding-top: 15px;" *ngIf="!showParentTable">
         <!-- Back Button -->
         <div class="go-back col-md-7" *ngIf="!goBack" style="display: flex; align-items: center; margin-left: -14px; ">
            <a (click)="handleGoBack()" style="display: flex; align-items: center; text-decoration: none; min-width: 100px; margin-top: 6px">
              <h4 style="cursor: pointer; margin: 0;">
                <em class="fa fa-angle-left"></em>
                <span i18n="@@back">&nbsp;{{ 'common.go_back' | translate }}</span>
              </h4>
            </a>
           
          <!-- Dropdown selected -->
          <div style="margin: 0 24px; display: block; width: 100%; ">
            <div style="display:inline-block;" class="question-form-label" i18n="@@chooseTemplate">
              {{ 'flow_responses.flow_selected' | translate }}
            </div>
            <div style="display: inline-block; width: 212px;" class="coversation-date-span flowDropDown">
              <ngx-select-dropdown 
                style="display:flex; min-width: 150px; margin:0 6px;"
                [multiple]="false"
                [(ngModel)]="selectedFlowName"
                [config]="dropdownConfig" 
                [options]="flowNames"
                (change)="onFlowNameChange($event)">
              </ngx-select-dropdown>
            </div>
          </div>
          </div>
          <!-- Download Button -->
          <div class="col-md-5" style=" display: flex; align-items: center; justify-content: right;">
            <button id="contactDownloadBtn" 
                    class="commonButton" 
                    *ngIf="showDownloadbutton" 
                    type="button" 
                    (click)="downloadFlows()" 
                    style=" height: 30px; width: 50px; border: 0; border-radius: 8px; position: absolute;right: -15px;top: 9px;" 
                    title="Download">
              <span  i18n="@@download">
                <i class="fa fa-download" aria-hidden="true" style="padding-top: 3px;"></i>
              </span>
              <span *ngIf="audienceLoader">
                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>&nbsp;
              </span>
            </button>
          </div>
      <table aria-labelledby="contactList1" mat-table matSort class="contactTable" [dataSource]="detailedDataTable"
             style="overflow: hidden; margin-top: 60px;">
             <ng-container matColumnDef="flow_name">
                <th id="flow_name" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'flow_responses.flow_name' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_name}}">
                   {{element.flow_name ? element.flow_name : '-'}}
                </td>
             </ng-container>
             <ng-container matColumnDef="flow_type">
                <th id="flow_type" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'flow_responses.flow_type' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.flow_type}}">
                  {{element.flow_type ? element.flow_type : '-'}}
               </td>
             </ng-container>
             <ng-container matColumnDef="username">
              <th id="username" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'flow_responses.username' | translate}}
              </th>
              <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.username}}">
                {{element.username ? element.username : '-'}}
             </td>
           </ng-container>
             <ng-container matColumnDef="user_identifier">
               <th id="user_identifier" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'flow_responses.user_identifier' | translate}}
               </th>
               <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.user_identifier}}">
                  {{element.user_identifier ? element.user_identifier : '-'}}
               </td>
            </ng-container>
            <ng-container matColumnDef="modified_on">
              <th id="modified_on" mat-header-cell *matHeaderCellDef i18n="@@matTab_name">
                {{ 'flow_responses.modified_on' | translate }}
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                style="cursor: default;"
                [title]="element.modified_on | date: dateFormat + ' h:mm a'" >
                {{ element.modified_on | date: dateFormat }} &nbsp;{{ element.modified_on | date: 'h:mm a' }}
              </td>
            </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumnsUib" style="cursor: context-menu;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsUib;" (click)="openUserDetails(row, userDetailsModal)" (keydown)="onRowKeydown($event, row, userDetailsModal)" tabindex="0"></tr>
            </table>

            <div class="row row-bottom" style="text-align:center;" *ngIf="( !detailedDataTable?.length) && !loadershow">
              <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate}}</p>
            </div>
          <app-pagination  [collectionSize]="totalSecondPage" [noPerPage]="itemsPerPage" [(page)]="pagelist" [maxSize]="3" [disabled]="paginationDisabled"
          (pageChange)="pageChangeSecond($event)"></app-pagination>
           <!-- NgbModal Template -->
           <ng-template #userDetailsModal let-modal>
            <div class="modal-header">
              <h3 class="modal-title text-center">Flow Responses</h3>
              <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
            </div>
            <div class="modal-body scrollable-content">
              <div class="user-details">
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                  <span style="display: flex; flex-direction: row; justify-content: space-between;width: 50%;">
                    <span class="detail-label">Flow Name</span>
                    <span class="detail-label align-items: center"><strong>:</strong></span>
                  </span>
                  <span style="width: 50%; text-align: left; padding-left: 5em;">{{selectedUser?.flow_name}}</span>
                </div>
                <div class="detail-row">
                  <span style="display: flex; flex-direction: row; justify-content: space-between;width: 50%;">
                    <span class="detail-label">Flow Type</span>
                    <span class="detail-label"><strong>:</strong></span>
                  </span>
                  <span style="width: 50%; text-align: left; padding-left: 5em;">{{selectedUser?.flow_type}}</span>
                </div>
                
                <!-- Conditionally render User Name field -->
                <div class="detail-row" >
                  <span style="display: flex; flex-direction: row; justify-content: space-between;width: 50%;">
                    <span class="detail-label">User Name</span>
                    <span class="detail-label"><strong>:</strong></span>
                  </span>
                  <span style="width: 50%; text-align: left; padding-left: 5em;">{{selectedUser?.username  ? selectedUser.username : '-'}}</span>
                </div>
                
                <div class="detail-row">
                  <span style="display: flex; flex-direction: row; justify-content: space-between;width: 50%;">
                    <span class="detail-label">User Identifier</span>
                    <span class="detail-label"><strong>:</strong></span>
                  </span>
                  <span style="width: 50%; text-align: left; padding-left: 5em;">{{selectedUser?.user_identifier}}</span>
                </div>
                
                <!-- Dynamic Questions -->
                <div *ngFor="let key of objectKeys(selectedUser?.questions)">
                  <div class="detail-row">
                    <span style="display: flex; flex-direction: row; justify-content: space-between; width: 50%;">
                      <span class="detail-label" [innerHTML]="key"></span>
                      <span class="detail-label">:</span>
                    </span>
                    <span style="width: 50%; text-align: left; padding-left: 5em;">
                      {{ selectedUser?.questions[key] }}
                    </span>
                  </div>
                </div>
                
              </div>
            </div>
          </ng-template>
</div>
</div>
