import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-intro',
  templateUrl: './self-intro.component.html',
  styleUrls: ['./self-intro.component.scss']
})

export class SelfIntroComponent implements OnInit {
  translatorLanguage: any;
  constructor(
    private readonly translate: TranslateService,
    public dialogRef: MatDialogRef<SelfIntroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly router: Router
  ) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
  }
  ngOnInit() {
    //
  }
  onCancel() {
    this.dialogRef.close();
  }
  onSubmit() {
    this.dialogRef.close();
    this.router.navigate([environment.dashboardPrefix + '/settings']);
  }
}
