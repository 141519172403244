import { Component, OnInit, Injector, ViewChild,ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WhatsappFlowsService } from './whatsapp-flows.service';
import { QuillEditorComponent } from 'ngx-quill';
import { RangeStatic } from 'quill';
import { UsersService } from '../users/users.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { UntypedFormArray,UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { FlowBuilderService } from '../flow-builder/flow-builder.service';
import { ToastrService } from 'ngx-toastr';
import { ConversationService } from '../conversation/conversation.service';
import { GetPaymentCountryList } from '../settings/countryList';

declare let Swal: any;


@Component({
  selector: 'app-whatsapp-flow',
  templateUrl: './whatsapp-flows.component.html',
  styleUrls: ['./whatsapp-flows.component.scss']
})

export class WhatsappFlowListComponent implements OnInit {
  actionHeader = 'WhatsApp Flow';
  editActionHeader = 'Update';
  whatsappFlowService: WhatsappFlowsService;
  whatsappFlows: any = [];
  whatsappForm: UntypedFormGroup;
  action: any = 'View';
  searchOne: any;
  displayedColumns: string[] = ['flow_name', 'flow_id', 'flow_end_response'];
  isOpen = false;
  public isEmojiPickerVisible: boolean;
  pagelist = 1;
  selecteUser: any;
  loadershow = false;
  screenWidth: number;
  totalCount: any = 0;
  translatorLanguage: any;
  parent_qa: any = '';
  actionType: any = 'none';
  flowAgentGroup: any = '';
  agentGroupList: any;
  usersService: UsersService;
  sharedService: SharedsService;
  updateemailForm: UntypedFormGroup;
  urlvalidation: any = false;
  validationClick: any = false;
  updatewebhookurl: any;
  updateheaderkey: any;
  updateheadervalue: any;
  selectedAnswerBoxIndex: any = 0;
  showVariableSelectBox = false;
  variableType: string;
  variableParam: any;
  projectVariable = true;
  systemVariable = true;
  userVariable = true;
  VariabledropdownOptions: any = [];
  conditionalResponseVariableList: any = [];
  userAttributeOptions: any = [];
  userAttributeConfig: any = {};
  variableConfig: any = {};
  variablesList: any;
  variables: any;
  variableArray = [];
  titleDrop: string;
  uploadloader = false;
  converstaionService: ConversationService;
  imageDetailsList: any = [];
  contentType = '';
  fileType = '';
  emailValidationErr :any = '';
  agentGroup: any;
  updateConnectToFlow: any = '';
  updateactiontype = false;
  public updatevalidationMsgs = {
    'updateemailAddress': [{
      type: 'email',
      message: 'Enter a valid email'
    }]
  };
  arabic_lan = false;
  emailerror: any = [];
  emailValue: any;
  isPaymentEnabled: any;
  paymentSettings = {
    amount: '',
    currencyCode: '',
    title: ''
  };
  userData: any;
  paymentErrormsg:any = '';
  paymentCountryList: any;
  selectedCurrencies: any;
  parenterrormsg = '';
  agentGrouperrormsg = '';
  guidedFlowsList: any = [];
  guidedFlowConfig: any = [];
  quillConfig =  {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ hargeader: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
    ],
  };
  @ViewChild('Editor') editorElementRef: QuillEditorComponent;
  @ViewChild('fileInputEdit') fileInputEdit: ElementRef;

  constructor(
    private readonly translate: TranslateService,
    private readonly injector: Injector,
    public formBuilder: UntypedFormBuilder,
    private readonly flowBuilderService: FlowBuilderService,
    private readonly toastr: ToastrService
  ) {
    this.whatsappFlowService = this.injector.get<WhatsappFlowsService>(WhatsappFlowsService);
    this.usersService = this.injector.get<UsersService>(UsersService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.converstaionService = this.injector.get<ConversationService>(ConversationService);
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.isPaymentEnabled =  this.userData?.appSettings?.projectDetail?.paymentSettings?.paymentEnabled ?? false;
  }

  ngOnInit() {
    const arg = { 'appId': this.sharedService.appId, 'search': '', 'limit': 0, 'skip': 0, };
    this.getAgentGroup(arg);
    const flow = { limit: 10000, skip: 0, language: 'en', };
    this.flowbuilderList(flow);
    this.loadDefaultVariables();
    this.verifyVariable({'variableType': 'system'});
    this.verifyVariable({'variableType': 'user'});
    this.verifyVariable({'variableType': 'project'});
    this.paymentCountryList = GetPaymentCountryList.getCountryList();
    this.selectedCurrencies =  this.paymentCountryList.filter(item => this.userData?.appSettings?.projectDetail?.paymentSettings?.selectedCurrencies?.includes(item?.currency));
    this.updateemailForm = this.formBuilder.group({
      updateemails: this.formBuilder.array([this.updatecreateEmailFormGroup('')])
    });
    const args = {
      limit: 10,
      skip: 0,
      search: ''
    };
    this.fetchWhatsappFLows(args);
    this.screenWidth = window.innerWidth;
  }

  showDetails(element) {
    this.isOpen = true;
    this.action = 'View';
    this.selecteUser = element;
  }

  addEmoji(event: any) {
    this.editorElementRef.quillEditor.insertText(
      this.selectedAnswerBoxIndex,
      event.emoji.native
    );
    this.isEmojiPickerVisible = false;
  }

  clickEmoji() {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    const selectionStart = this.editorElementRef?.quillEditor?.getSelection();
    if (selectionStart) {
      this.selectedAnswerBoxIndex = selectionStart.index;
    }
  }

  checkWindowSize() {
    return this.screenWidth <= 992;
  }

  updateParentQaContent(event) {
    this.parent_qa = event.html;
    const selection: RangeStatic = {
      index: this.editorElementRef?.quillEditor?.getLength(),
      length: 0,
    };
    this.editorElementRef?.quillEditor?.setSelection(selection);
  }

  searchFlows(value: any) {
    this.searchOne = value;
    const args = {
      limit: 10,
      skip: this.pagelist * 10 - 10,
      search: value
    };
    this.fetchWhatsappFLows(args);
  }

  pageChange(page) {
    this.pagelist = page;
    const args = { limit: 10, skip: this.pagelist * 10 - 10, search: ''};
    this.fetchWhatsappFLows(args);
  }

  fetchWhatsappFLows(arg) {
    this.loadershow = true;
    this.whatsappFlowService.fetchWhatsappFLows(arg).subscribe((data) => {
      if (data.status === 200 && data?.info?.length) {
        this.loadershow = false;
        this.whatsappFlows = data?.info ?? [];
        this.isOpen = true;
        this.totalCount = data?.totalcount ?? 0;
        this.showDetails(this.whatsappFlows[0]);
      } else {
        this.isOpen = false;
        this.loadershow = false;
        this.whatsappFlows = [];
      }
    },
      (error) => {
        this.loadershow = false;
        console.error('Error fetching WhatsApp flows:', error);
        this.whatsappFlows = [];
      }
    );
  }

  editFlow() {
    this.action = 'Update';
    this.validationClick =  true;
    this.urlvalidation = false;
    this.parent_qa = this.selecteUser?.flow_end_response;
    this.imageDetailsList = JSON.parse(this.selecteUser?.attachmentDetails) || [];
    this.actionType  = JSON.parse(this.selecteUser?.submit_action)?.action ?? 'none';
    if (this.actionType === 'transfer_to_agent') {
      this.flowAgentGroup = JSON.parse(this.selecteUser?.submit_action)?.groupId ?? '';
    } else if (this.actionType === 'send_api') {
      const header = JSON.parse(this.selecteUser?.submit_action)?.header_data;
      this.updatewebhookurl = JSON.parse(this.selecteUser?.submit_action)?.webhook_url;
      this.updateheaderkey = Object.keys(header)[0];
      this.updateheadervalue =  Object.values(header)[0];
      this.urlvalidation =  true;
    } else if (this.actionType === 'send_email') {
      const mails = JSON.parse(this.selecteUser?.submit_action)?.receiver_email ?? [];
      const updateemailsArray = this.updateemailForm.get('updateemails') as UntypedFormArray;
      updateemailsArray.clear();
      mails.forEach(email => {
        updateemailsArray.push(this.updatecreateEmailFormGroup(email));
      });
    } else if (this.actionType === 'payment') {
      this.paymentSettings = JSON.parse(this.selecteUser?.submit_action)?.paymentSettings
    } else if(this.actionType === 'connect_to_flows') {
      const flowId = JSON.parse(this.selecteUser?.submit_action)?.flow_id;
      this.updateConnectToFlow = this.guidedFlowsList.find((item)=> item._id === flowId);
    }
  }

  getAgentGroup(args) {
    this.usersService.listAgentGroup(args).subscribe(
      (data) => {
        if (data?.data) {
          this.agentGroupList = data?.data?.data;
          this.agentGroupList = this.agentGroupList.filter(function (item) {
            return item.users.length > 0;
          });
          this.agentGroupList.unshift({ _id: '', group_name: 'Select' });
        }
      });
  }

  private updatecreateEmailFormGroup(data): UntypedFormGroup {
    return new UntypedFormGroup({
      'updateemailAddress': new UntypedFormControl(data, Validators.email)
    });
  }

  public updateaddEmailFormGroup(value) {
    const updateemails = this.updateemailForm.get('updateemails') as UntypedFormArray;
    updateemails.push(this.updatecreateEmailFormGroup(value));
  }

  processActionChange(value) {
    if (value === 'send_api') {
      this.urlvalidation = true;
    } else {
      this.urlvalidation = false;
    }
  }

  validateApiUpdate() {
    const arg = {
      header_data: {},
      webhook_url: this.updatewebhookurl,
      flowName: this.selecteUser?.flow_name ?? ''
    };
    const headerkey = this.updateheaderkey ? this.updateheaderkey : '';
    if (headerkey) {
      arg.header_data[this.updateheaderkey] = this.updateheadervalue ? this.updateheadervalue : '';
    }
    this.flowBuilderService.apiValidate(arg).subscribe(data => {
      if (data['status'] === 200) {
        this.urlvalidation = false;
      } else {
        this.urlvalidation = true;
        this.validationClick = true;
        this.toastr.error('', this.translate.instant('toastr.api_Validate_failed'));
      }
    }, (error) => {
      this.urlvalidation = true;
    });
  }

  setValues(value1, values2) {
    if (value1) {
      return value1;
    } else {
      return values2;
    }
  }

  showSelectBox() {
    this.variableParam = '';
    if (this.showVariableSelectBox) {
      this.showVariableSelectBox = false;
    } else {
      this.showVariableSelectBox = true;
      this.variableType = 'project';
    }
  }

  verifyVariable(obj) {
    this.flowBuilderService.getVariablesList(obj).subscribe((data) => {
      if (data?.info?.length === 0) {
        if (obj.variableType === 'project') {
          this.projectVariable = false;
          this.variableType = 'system';
          this.conditionalResponseVariableList = this.verifyCondition(this.variableType === 'project', data.info, this.conditionalResponseVariableList);
          this.VariabledropdownOptions = this.conditionalResponseVariableList;
        } else if (obj.variableType === 'user') {
          this.userVariable = false;
          this.variableType = 'system';
        } else if (obj.variableType === 'system') {
          this.systemVariable = false;
        }
      } else if (data?.info?.length > 0) {
        if (obj.variableType === 'project') {
          this.conditionalResponseVariableList = data.info;
          this.VariabledropdownOptions = this.setValues(data.info , []);
        }
        if (obj.variableType === 'user') {
          this.userAttributeOptions = this.setValues(data.info , []);
          if (this.userAttributeOptions.length > 0) {
            this.userAttributeConfig = {
              displayKey: 'attribute_name',
              search: true,
              height: '175px',
              placeholder: 'Select',
              limitTo: this.userAttributeOptions.length,
              moreText: 'more',
              noResultsFound: 'No results found!',
              searchPlaceholder: 'Search',
              searchOnKey: 'attribute_name',
            };
          }
        }
      }
    });
  }

  verifyCondition(cond , val1, val2) {
    if (cond) {
      return val1;
    } else {
      return val2;
    }
  }

  onVariableRadioChange() {
    this.loadVariablesList();
  }

  loadVariablesList() {
    const args = {
      variableType: this.variableType
    };
    this.flowBuilderService.getVariablesList(args).subscribe(data => {
      if (data.status === 200) {
        this.variablesList = this.setValues(data.info , []);
        this.VariabledropdownOptions = this.setValues(data.info , []);
        if (this.variableType === 'project' || this.variableType === 'system' ) {
          this.variables = this.assignVariableList();
          if (data?.info?.length === 0 && this.variableType === 'project') {
            this.projectVariable = false;
            this.variableType = 'system';
            const arg = {
              variableType: this.variableType
            };
            this.flowBuilderService.getVariablesList(arg).subscribe((data1) => {
              this.variablesList = this.setValues(data1.info , []);
              this.VariabledropdownOptions = this.setValues(data1.info , []);
              this.variables = this.assignVariableList();
            });
          }
          this.variableConfig = {
            displayKey: 'variable' ,
            search: true,
            height: '175px',
            placeholder: 'Select',
            limitTo: this.VariabledropdownOptions.length,
            moreText: 'more',
            noResultsFound: 'No results found!',
            searchPlaceholder: 'Search',
            searchOnKey: 'variable',
          };
        } else {
          this.configureUserVariables();
        }
      }
    }, err => {
      this.toastr.error('', this.translate.instant('toastr.something_wrong'));
    });
  }

  assignVariableList() {
    if (this.variablesList && this.variablesList.length > 0) {
      return this.variablesList[0];
    } else {
      return [];
    }
  }

  configureUserVariables() {
    if (this.variableType === 'user' && this.VariabledropdownOptions.length > 0) {
      this.variableConfig = {
        displayKey: 'attribute_name' ,
        search: true,
        height: '175px',
        placeholder: 'Select',
        limitTo: this.VariabledropdownOptions.length,
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'attribute_name',
      };
    }
  }

  onVariableClick() {
    const selectionStart = this.editorElementRef?.quillEditor?.getSelection();
    if (selectionStart) {
      this.selectedAnswerBoxIndex = selectionStart.index;
    }
  }

  onVariableChanged(event) {
    let charType;
    if (this.variableParam) {
      this.variableArray.push(this.variableParam);
      if (this.variableType === 'user') {
        charType = '{' + this.variableParam.attribute_name + '}';
      } else {
        charType = '{' + this.variableParam.variable + '}';
      }
    } else {
      this.variableParam = '';
    }
    this.editorElementRef.quillEditor.insertText(this.selectedAnswerBoxIndex, charType);
    this.variableParam = '';
  }

  onHover(event: MouseEvent) {
    const target = event.target['innerText'];
    this.titleDrop = target;
  }

  loadDefaultVariables() {
    this.variableType = 'project';
    this.loadVariablesList();
  }

  editFileProcess(event) {
    this.uploadloader = true;
    const Editfiles = event.target.files; // FileList object
    const file = Editfiles[0];
    const fileName = Editfiles[0]?.name ?? '';
    const pattern1 = /jpeg-*/;
    const pattern2 = /png-*/;
    const pattern3 = /pdf-*/;
    const pattern4 = /mp4-*/;
    const pattern5 = /msword-*/;
    const pattern6 =
      /vnd.openxmlformats-officedocument.wordprocessingml.document-*/;
    const pattern7 = 'audio/mpeg';
    const pattern8 = 'doc';
    const pattern9 = 'docx';
    const fileExtinctionName = event.target.files[0].name.split('.').pop();

    const reader = new FileReader();

    const sizeinMb = (file?.size / (1024 * 1024)).toFixed(2);
    if (
      !file.type.match(pattern1) &&
      !file.type.match(pattern2) &&
      !file.type.match(pattern3) &&
      !file.type.match(pattern4) &&
      !file.type.match(pattern5) &&
      !file.type.match(pattern6) &&
      !file.type.match(pattern7) &&
      pattern8 !== fileExtinctionName &&
      pattern9 !== fileExtinctionName
    ) {
      this.toastr.error('', this.translate.instant('toastr.invalid_format'));
      this.assignFileInput();
      this.uploadloader = false;
      return;
    }
    if ((file.type.match(pattern1) || file.type.match(pattern2)) && parseFloat(sizeinMb) > 5 ) {
        this.toastr.error(
            '',
            this.translate.instant('toastr.uploadedFile_limit_5mb')
        );
        this.assignFileInput();
        this.uploadloader = false;
        return;
    }
    if (!file.type.match(pattern1) && !file.type.match(pattern2) && parseFloat(sizeinMb) > 16) {
      this.toastr.error(
        '',
        this.translate.instant('toastr.uploadedFile_limit_16mb')
      );
      this.assignFileInput();
      this.uploadloader = false;
      return;
    }
    let file_type;
    if (file.type) {
      file_type = file.type;
    } else if (pattern8 === fileExtinctionName) {
        file_type = 'application/msword';
    } else {
        file_type =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    this.assignFileType(file);
    reader.readAsBinaryString(file); // NOSONAR
    reader.onload = (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file_type,
        filename: this.setValues(fileName, ''),
        bucketType: 'content'
      };
      this.converstaionService.fileupload(args).subscribe(
        (response) => {
          this.fileuploadResponse(response,file);
        },
        (err) => {
          this.uploadloader = false;
        }
      );
    };
  }

  assignFileInput() {
    if (this.fileInputEdit?.nativeElement) {
      this.fileInputEdit.nativeElement.value = '';
    }
  }

  fileuploadResponse(response, file2) {
    this.uploadloader = false;
    this.assignFileInput();
    if (response.info.url) {
      const imageDataObj = {
        mediaType: this.contentType,
        type: this.fileType,
        title: file2,
        url: response.info.url_orginal
      };
      this.imageDetailsList.push(imageDataObj);
    } else {
      this.toastr.info('', response.info);
    }
  }

  assignFileType(file) {
    if (file.type.search('image') !== -1) {
      this.contentType = 'image';
      this.fileType = file.type;
    } else if (file.type.search('video') !== -1) {
      this.contentType = 'video';
      this.fileType = file.type;
    } else if (file.type.search('pdf') !== -1) {
      this.contentType = 'document';
      this.fileType = file.type;
    } else if (file?.name.search('.doc') !== -1) {
      this.contentType = 'docx';
      this.fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (file.type.search('audio') !== -1) {
      this.contentType = 'audio';
      this.fileType = file.type;
    } else {
      this.contentType = file.type;
      this.fileType = file.type;
    }
  }


  removeImageOnClick(url: any) {
    Swal.fire({
      title:
        '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i>&nbsp; Are you sure you want to remove the file?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',
    }).then((result) => {
      if (result.value) {
        this.imageDetailsList = this.imageDetailsList.filter(item => item.url !== url);
        this.fileType = '';
      }
    });
  }

  public updateremoveOrClearEmail(i: number) {
    this.emailerror =''
    this.emailValue=''
    const updateemails = this.updateemailForm.get('updateemails') as UntypedFormArray;
    if (updateemails.length > 1) {
      updateemails.removeAt(i);
    } else {
      updateemails.reset();
    }
  }

  handleInput(event: string, i: number): void {
  const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  if(event){
    this.emailValue = event;
  }
  else{
    this.emailValue = '';
  }
    if (event.trim() === '') {
      this.emailerror[i] = false; 
      this.emailValidationErr = ''; 
      return; 
    }
  
    if (!EMAIL_REGEXP.test(event)) {
      this.emailerror[i] = true; 
      this.emailValidationErr = ''; 
    } else {
      this.emailerror[i] = false;
      this.emailValidationErr = ''; 
    }
  }

  changeSelectedCurrency(country) {
    this.paymentSettings.currencyCode = country.currency;
  }

  onCancel() {
    this.isOpen = false;
    this.actionType = 'none';
    this.updateemailForm.reset();
    this.urlvalidation = false;
    this.updatewebhookurl = '';
    this.updateheaderkey = '';
    this.updateheadervalue = '';
    this.paymentErrormsg = '';
    this.flowAgentGroup = '';
    this.imageDetailsList = [];
    this.emailerror =''
    this.parent_qa = '';
    this.validationClick =  false;
    this.paymentSettings =  {
      amount: '',
      currencyCode: '',
      title: ''
    };
    this.parenterrormsg = '';
    this.agentGrouperrormsg = '';
    this.emailValidationErr = '';
     this.emailValue = '';
    const args = { limit: 10, skip: 0, search: '' };
    this.fetchWhatsappFLows(args);
    
  }

  updatewhatsAppFlow() {
    if (!this.checkErrorStatus()) {
      return;
  
    }
    const arg = this.setUpdateFlowArgument();
    this.loadershow = true;
    this.whatsappFlowService.updateWhatsappFLows(arg).subscribe((data) => {
      if (data.status === 200 && data?.info?.length) {
        this.loadershow = false;
        this.toastr.info('', data?.info);
      } else {
        this.isOpen = false;
        this.loadershow = false;
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      }
      this.onCancel();
    },
      (error) => {
        this.loadershow = false;
        this.toastr.error('', this.translate.instant('toastr.something_wrong'));
      }
    );

  }

  setUpdateFlowArgument() {
    const arg = {
      'flow_id': this.selecteUser?.flow_id,
      'flow_end_response': this.textformat(this.parent_qa),
      'attachmentDetails': this.imageDetailsList,
      'variableArray':this.variableArray
    };
    this.processSendAPIReport(arg);
    this.handleTransferToAgent(arg);
    this.handleCreatePayemnt(arg);
    this.processEmailReports(arg);
    this.handleConnectToFLows(arg);
    return arg;
  }

  checkErrorStatus() {
    if (this.actionType === 'transfer_to_agent' && !this.flowAgentGroup) {
      this.agentGrouperrormsg = this.translate.instant('flowBuilder.agentGrouperrormsg');
      return false;
    } else {
      this.agentGrouperrormsg = '';
    }
    if (this.parent_qa === '' || this.parent_qa === undefined || this.parent_qa === null) {
      this.parenterrormsg = 'Field is required';
      return false;
    } else {
      this.parenterrormsg = '';
    }
    if (this.actionType === 'payment') {
      const inValid = Object.values(this.paymentSettings).some((v) => v === '');
      if (inValid) {
        this.paymentErrormsg = 'Field is required';
        return false;
      }
    }
    if (this.actionType === 'send_email') {
      if (this.emailValue.trim() === '') {
        this.emailValidationErr = 'Field is required';
        return false;
      }
      if (this.emailValue.trim() && !this.emailValue.includes('@')) {
        this.emailValidationErr = 'Field is required';
        return false;
      }
      if (this.emailerror.includes(true)) {
        this.emailValidationErr = 'Field is required';
        return false;
      }
    }
    return true;
  }

  processSendAPIReport(arg) {
    if (this.actionType === 'send_api') {
      if (this.urlvalidation || !this.updatewebhookurl) {
        return true;
      } else {
        const apidata = {
          'action': this.actionType,
          'webhook_url': this.updatewebhookurl,
          'header_data': {},
        };
        const headerkey = this.setValues(this.updateheaderkey , '');
        if (headerkey) {
          apidata.header_data[this.updateheaderkey] = this.setValues(this.updateheadervalue , '');
        }
        arg['submit_action'] = apidata;
      }
    }
  }

  textformat(text) {
    return text?.replace(/&(?:amp|nbsp);/g, (match) => (match === '&amp;' ? '&' : ''))?.replace(/<\/?[a-z][a-z0-9]*\b[^>]*>/gi, '');
  }

  processEmailReports(arg) {
    const emailValues = [];
    const emailvalue = this.setEmailValue();
    emailvalue.forEach(element => {
      if (element.updateemailAddress && element.updateemailAddress !== '') {
        emailValues.push(element.updateemailAddress);
      }
    });

    if (this.actionType === 'send_email') {
      const emaildata = {
        'action': this.actionType,
        'receiver_email': emailValues
      };

      if (emailValues.length > 0) {
        arg['submit_action'] = emaildata;
      }
      if (this.emailerror.includes(true)) {
        return true;
      }
    }
  }

  handleTransferToAgent(arg) {
    if (this.actionType === 'transfer_to_agent') {
      arg['submit_action'] = {
        'action': this.actionType,
        'groupId': this.flowAgentGroup
      };
    }
  }

  handleCreatePayemnt(arg) {
    if (this.actionType === 'payment') {
      if (this.paymentSettings) {
        arg['submit_action'] = {
          'action': this.actionType,
          'paymentSettings': this.paymentSettings
        };
      }
    }
  }

  handleConnectToFLows(arg) {
    if (this.actionType === 'connect_to_flows' && this.updateConnectToFlow._id) {
      arg['submit_action'] = {
        'action': this.actionType,
        'flow_id': this.updateConnectToFlow._id
      };
    }
  }

  setEmailValue() {
    if (this.updateemailForm?.value) {
      return this.updateemailForm.value.updateemails;
    } else {
      return '';
    }
  }

  flowbuilderList(arg) {
    this.flowBuilderService.listNewFlow(arg).subscribe(data => {
      if (data) {
        const response = data['flows'] ? data['flows'] : [];
        this.guidedFlowsList = response.filter(element => element.status !== false);
      }
      this.guidedFlowConfig = {
        displayKey: 'name',
        search: true,
        height: '175px',
        placeholder: 'Select',
        limitTo: this.guidedFlowsList?.length,
        moreText: 'more',
        noResultsFound: 'No results found!',
        searchPlaceholder: 'Search',
        searchOnKey: 'name',
      };
    });
  }
}

