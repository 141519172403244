<div class="">
    <div class="col-md-12" class="modal-content" style="border-radius: 6px 6px 0px 0px !important">
        <div class="modal-header headerModal" style="text-align: center">
            <button type="button" class="close" (click)="onCancel()">
                &times;
            </button>
        </div>
        <div style="padding: 2%">
            <div style="text-align: center; padding: 1% 2% 3% 2%">
                <span style="font-size: 18px">{{"selfIntro.channel_message" | translate}}</span>
            </div>
            <div class="auth-block-body" style="height: 85px; width: auto">
                <div class="login-input submit" style="padding: 2%">
                    <div class="login-submit" style="padding: 0;margin: 0;height: auto;border-radius: 5px;">
                        <button id="submitForgotPassword" class="btn-default btn-auth" type="button"
                            (click)="onSubmit()">
                            {{ "selfIntro.addChannel" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>