import { AbstractControl } from '@angular/forms';
export class Helpers {
    static emailValidator(control: AbstractControl) {
        if (control.get('email') && control.get('email').value != null && control.get('email').value !== '' && control.get('email').value !== undefined) {
          const urlRegex = /^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          const valid = urlRegex.test(control.get('email').value);
          if (!valid) {
            if (control.get('email').errors != null) {
              control.get('email').errors['invalidEmail'] = true;
            } else {
              control.get('email').setErrors({ invalidEmail: true });
            }
          } else {
            return null;
          }
        }
    }

    static passwordValidator(control: AbstractControl) {
        if (control.get('password') && control.get('password').value != null && control.get('password').value !== '' && control.get('password').value !== undefined) {
          const urlRegExp =
          '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*])[A-Za-z\\d!@#$%^&*]{10,}'; // NOSONAR
          const res = control.get('password').value.match(urlRegExp);
          if (res == null) {
            if (control.get('password').errors != null) {
              control.get('password').errors['invalidPassword'] = true;
            } else {
              control.get('password').setErrors({ invalidPassword: true });
            }
          } else {
            return null;
          }
        }
    }

}
